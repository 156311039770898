.sankey-node {
  background-color: #ffffff !important;
  border: 1px solid #f7f7f7 !important;
  color: var(--black-200);
}

.sankey-node > .sankey-percent {
  color: var(--grey) !important;
}

.sankey-goal > .sankey-percent-goal {
  color: rgba(255, 255, 255, 0.7) !important;
}

.sankey-goal {
  background-color: var(--main-blue);
  color: white;
  opacity: 1 !important;
}

.sankey-goal > .sankey-percent {
  color: rgba(255, 255, 255, 0.7) !important;
}

.sankey-node:hover,
.sankey-node.sankey-selected {
  background-color: #f7f7f7 !important;
  border: 1px solid #d7d7d7 !important;
}

.visx-sankey-link.sankey-candidate {
  stroke-opacity: 1;
  stroke: #707070;
}

.visx-sankey-link {
  clip-path: inset(0 15px 0 15px);
}

.visx-sankey-link:hover,
.visx-sankey-link.sankey-selected {
  stroke-opacity: 1;
  stroke: #7f66ff;
}

.visx-sankey-node:hover {
  filter: brightness(1.3);
}

.visx-sankey-demo-controls {
  font-size: 12px;
}

.sankey-goal-img {
  width: 27px;
  height: 18px;
  margin-bottom: 26px;
}

.sankey-translucent {
  opacity: 0.2;
}

.sankey-analyze-funnel-btn-container {
  position: relative;
}

.sankey-analyze-funnel-btn-container > button {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  bottom: 100px;
}
