.client-reports-parent {
  margin-top: 23px;
}

.nav-back {
  color: #000 !important;
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 36px;
  margin-bottom: 25.91px;
}

.nav-back-title {
  margin-left: 8px;
}

.line {
  width: 100%;
  height: 1px;
  background: #eee;
}

.filter-section {
  margin-top: 50px;
  margin-bottom: 49.43px;
}

.filter-section-title {
  color: #0f0c22;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.filter-control,
.filter-section .filter-control {
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  background: white;
  color: #0f0c22;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  width: 165px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.filter-section .menu {
  margin-top: 5px;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #eee;
  background: white;
}

.filter-section .option {
  font-size: 14px;
  font-weight: 500;
  border: 5px;
  border-radius: 10px;
  padding: 12px 8px;
}

.option:hover {
  background: var(--blue-opacity10);
}

.timeline {
  margin-bottom: 30px;
  color: var(--black-100);
  font-feature-settings:
    "cv11" on,
    "cv01" on,
    "ss01" on;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.project-card {
  border: 1px solid #d7d7d7;
  border-radius: 15px;
  padding: 0;
}

.project-card .insight {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  color: #000;
  font-style: normal;
  letter-spacing: 1.023px;
  text-transform: uppercase;
}

.project-card .suggestion {
  background: linear-gradient(0deg, #f7f7f7, #f7f7f7);
  padding: 49.98px 50.89px;
  border-radius: 15px 15px 0 0;
}

.project-card .suggestion-title {
  font-weight: 400;
  font-size: 38px;
  overflow-wrap: break-word;
}

.project-card .suggestion-text {
  overflow-wrap: break-word;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  text-align: left;
}

.project-card .results-title {
  font-size: 13px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-align: left;
  color: #707070;
  text-transform: uppercase;
}

.project-card .results-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  color: #707070;
}

.project-card .results-img {
  gap: 34px;
}

.location-title {
  display: flex;
  gap: 10px;
}

.project-img {
  border-radius: 9.8px;
  border: 4.9px;
  display: flex;
  justify-content: flex-end;
}

.project-card .recommendations {
  padding: 49.98px 50.89px;
  color: #000;
  stroke-width: 0.612px;
  stroke: #e1d6da;
}

.project-card .recommendation-title {
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 24.5px; /* 204.167% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-left: 8px;
  color: #000;
  text-transform: uppercase;
}

.project-card .recommendation-body {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.95px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.project-card .rework {
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  background: #f7f7f7;
  color: #707070;
  font-feature-settings:
    "cv11" on,
    "cv01" on,
    "ss01" on;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.project-card .rework .rework-text {
  margin-left: 5px;
}

.project-card .rework button {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 12px 16px;
  border-radius: 5px;
  line-height: 20px;
  letter-spacing: 0;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}

.project-card .rework button.btn1 {
  color: #ffffff;
  background: #0062fe;
  margin-right: 12px;
}

.project-card .rework button.btn2 {
  color: #2a00ff;
  background: rgba(42, 0, 255, 0.1);
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-text {
  z-index: 100;
  visibility: hidden;
  background-color: #231f20;
  color: white;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  width: 200px;
  height: 45px;
  position: absolute;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;

  top: 35px;
  left: 80px;
}

.custom-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tab-item {
  background-color: transparent;
  border: none;
  border-radius: 0 !important;
  padding: 3px 0 12px 0;
  margin-right: 32px;
}

.activetab {
  padding-bottom: 10px;
  border-bottom: 2px solid #2a00ff !important;
}

.tab-item:hover {
  background-color: transparent !important;
}

.tab-item:active {
  background-color: transparent !important;
}

.tab-item-text {
  color: #0f0c22 !important;
  font-weight: 500;
}

.tab-item-text-active {
  font-weight: 700;
  color: black !important;
}

.tab-bar {
  width: 48px;
  height: 2px;
  margin-bottom: -15.5px;
}
