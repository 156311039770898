.popper-tooltip-container {
  z-index: 9999;
  inset: none !important;
}

.sankey-popper {
  max-width: 300px;
  background-color: #ffffff !important;
  border: 1px solid #f7f7f7 !important;
  color: var(--black-200) !important;
  padding: 12px;
  border-radius: 8px !important;
  font-family: "manrope" !important;
  overflow: hidden !important;
}

.heatmap-popper {
  transform: translate(0, 0) !important;
  overflow: visible;
  background-color: #0f0c22 !important;
  color: white !important;
  box-shadow: 4px -3px 0px 0px #2a00ff;
  font-size: 16px;
  padding: 12px;
  font-weight: 700;
  font-family: "satoshi" !important;
  border-radius: 8px !important;
}
