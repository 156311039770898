:root {
  --main-blue: #2a00ff;
  --medium-blue: #0062fe;
  --blue-opacity10: rgba(42, 0, 255, 0.1);
  font-size: 14px;
  --bs-primary: red;
  --standard-border-radius: 8px;
  --standard-font-color: #1f1f24;
  --projects-tab-color: #000000;
  --projects-tab-color-active: #2a00ff;
  --selected-background: #eef5fc;
  --white: #ffffff;
  --off-white: #f1f1f1;
  --off-white-two: #f7f7f7;
  --off-white-three: #efefef;
  --dashboard-trend-positive: #25a93a;
  --dashboard-trend-negative: #dd0d0d;
  --dashboard-trend-neutral: #ec8d10;
  --black-100: #1c1c1c;
  --black-200: #0f0c22;
  --grey: #707070;
  --grey-two: #a1a1a1;
  --grey-three: #d7d7d7;
  --gold: #ffc900;
  --red: #eb0d35;
}

body {
  font-family: "satoshi" !important;
  color: var(--standard-font-color) !important;
  overflow-x: hidden;
}

html {
  min-width: 600px;
}

h5 {
  font-size: 16px !important;
}

@font-face {
  font-family: "satoshi";
  src:
    local("satoshi"),
    url("./fonts/satoshi/WEB/fonts/Satoshi-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "satoshi";
  src: url("./fonts/satoshi/WEB/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "satoshi";
  src: url("./fonts/satoshi/WEB/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "satoshi";
  src: url("./fonts/satoshi/WEB/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "satoshi";
  src: url("./fonts/satoshi/WEB/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "satoshi";
  src: url("./fonts/satoshi/WEB/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;900&display=swap");

.manrope {
  font-family: "Manrope", "Times New Roman", Times, serif;
}

.main-parent {
  transition:
    padding-left 0.2s ease-out,
    padding-right 0.2s ease-out;
  padding: 160px 80px 0px 160px;
  width: 100%;
  overflow: visible;
}

.reduced {
  padding-left: 444px !important;
}

button {
  border: none;
  border-radius: var(--standard-border-radius) !important;
  color: white;
  padding: 10px 18px;
  transition: 300ms;
}

.btn-none {
  padding: 0;
  background: transparent;
}

.btn-primary {
  background-color: var(--main-blue);
}
.btn-primary:disabled {
  background-color: #ebebeb;
  color: #979797;
  border: none;
}

.btn-secondary {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.btn-tertiary {
  color: black;
  background-color: white;
  border: 1.5px solid #e9edf4;
}

.btn-quaternary {
  background-color: white;
  color: var(--main-blue);
  border: 2px solid var(--main-blue);
}

.btn-quinary {
  background-color: transparent;
  color: black;
  border: 1px solid #d7d7d7;
}

.btn-senary {
  color: white;
  background-color: transparent;
  border: 2px solid white;
}

.btn-septenary {
  color: black;
  background-color: transparent;
  border: 2px solid black;
}

.btn-octonary {
  background-color: #f7f7f7;
  color: black;
  border: 1px solid #d7d7d7;
}

.btn-nonary {
  background-color: white;
  color: var(--main-blue);
  border: 2px solid var(--main-blue);
}

.btn-nonary:hover,
.btn-denary:hover {
  background-color: var(--main-blue);
  color: white;
  border: 2px solid var(--main-blue);
  transition: all 0.3s;
}

.btn-denary {
  background-color: transparent;
  color: var(--main-blue);
  border: 2px solid var(--main-blue);
}

.btn-undenary {
  background-color: white;
  color: var(--main-blue);
}

.btn-v6-fix {
  border-radius: 4px !important;
  height: 40px;
  margin-right: 50px;
  padding: 9px 10px;
}

.text-input {
  background-color: var(--off-white-two);
  color: var(--grey);
  border: none;
  height: 44px;
  border-radius: var(--standard-border-radius);
  padding: 12px;
}

.btn-invis {
  background: transparent;
}

h1 {
  font-size: 40px !important;
}

h5 {
  font-size: 16px !important;
}

a {
  text-decoration: none !important;
  color: var(--main-blue);
}

.help-from-team {
  background-color: var(--off-white);
  padding: 16px;
  border-radius: var(--standard-border-radius);
  font-size: 14px;
  display: flex;
  align-items: center;
}

.report-sidebar {
  min-width: 0 !important;
}

@media screen and (max-width: 576px) {
  .reduced,
  .main-parent {
    padding: 0 !important;
  }

  .carousel {
    width: 100%;
  }
}

@media screen and (max-width: 1512px) {
  .main-parent {
    padding-left: 220px;
  }

  .report-sidebar {
    width: 300px !important;
  }
}

@media screen and (max-width: 1199px) {
  .main-parent {
    padding-left: 170px;
  }

  .report-sidebar {
    width: 250px !important;
  }
}

@media screen and (max-width: 898px) {
  .main-parent {
    padding-right: 70px;
  }

  .report-sidebar {
    width: 200px !important;
  }
}

@media screen and (max-width: 802px) {
  .main-parent {
    padding-left: 110px;
  }
  .report-sidebar {
    width: 150px !important;
  }
}

@media screen and (max-width: 704px) {
  .main-parent {
    padding-right: 0;
  }
}

.disclaimer-link {
  color: grey;
  text-decoration: underline !important;
}

.custom-modal:focus-visible {
  outline: none;
}

.spinner-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.styled-link {
  text-decoration: underline !important;
  text-underline-offset: 4px;
}

.fs-24 {
  font-size: 24px;
}

.checkbox-input:hover {
  cursor: pointer;
}

.checkbox-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  outline: 2px solid #0f0c22;
  margin-left: 2px;
  transition: 200ms;
}

.checkbox-input:checked {
  background-color: #2a00ff;
  background-clip: content-box;
  padding: 2px;
}

.fvc {
  display: flex;
  align-items: center;
  height: 100%;
}

.vhc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hc {
  display: flex;
  justify-content: center;
}

.txt-center {
  text-align: center;
}

.grey-hover:hover {
  background-color: #f7f7f7;
}

.lh-25 {
  line-height: 25px;
}

.lh-224 {
  line-height: 22.4px;
}

.lh-20 {
  line-height: 20px;
}

.lh-252 {
  line-height: 25.2px;
}

.lh-2371 {
  line-height: 23.71px;
}

.lh-196 {
  line-height: 19.6px;
}

.border-none {
  border: none !important;
}

/* Global Tabs Style */
.nav-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 12px;
}
.nav-tabs .nav-item {
}
.nav-tabs .nav-item .nav-link {
  padding: 12px 0;
  border: none;
  position: relative;
  font-size: 18px;
  font-family: "Satoshi";
  font-weight: 400;
  line-height: 24.3px;
  color: var(--grey);
}
.nav-tabs .nav-item .nav-link:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: block;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s;
}
.nav-tabs .nav-item .nav-link.active {
  color: var(--main-blue);
  font-weight: 700;
}
.nav-tabs .nav-item .nav-link.active:after {
  background-color: var(--main-blue);
}

.tab-content {
}
.tab-content .tab-pane {
}
.tab-content .tab-pane.active {
}

.payment-square-li-container div {
  text-indent: 2px;
  line-height: 16.8px;
  list-style-position: inside;
}

.payment-square-li-container div::before {
  content: "•";
  padding-right: 11px;
}

.fc-light-grey {
  color: #e3e3e7 !important;
}

.fc-blue {
  color: var(--main-blue) !important;
}

.fc-black2 {
  color: #0f0c22 !important;
}

.fc-grey {
  color: #707070 !important;
}

.fc-black {
  color: var(--projects-tab-color);
}

.fc-red2 {
  color: var(--red) !important;
}

.fc-green2 {
  color: #27dab7;
}

.fc-gold {
  color: var(--gold);
}

.img-keep-ratio {
  object-fit: contain !important;
}

.fs-16 {
  font-size: 16px !important;
}

.bg-blue {
  background-color: var(--main-blue) !important;
}

.bg-grey {
  background-color: var(--off-white-two) !important;
}

.bg-red {
  background-color: var(--dashboard-trend-negative) !important;
}

.bg-green {
  background-color: var(--dashboard-trend-positive) !important;
}

.bg-yellow {
  background-color: var(--dashboard-trend-neutral) !important;
}

.fs-30 {
  font-size: 30px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.p-a {
  position: absolute;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt--15 {
  margin-top: -15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml--10 {
  margin-left: -10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-42 {
  margin-left: 42px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml--20 {
  margin-left: -20px !important;
}

.p-0 {
  padding: 0 !important;
}

.fs-1334 {
  font-size: 13.34px !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-400 {
  font-weight: 400;
}

.fs-18 {
  font-size: 18px;
}

.fs-14 {
  font-size: 14px;
}

.mt--8px {
  margin-top: -8px;
}

.mt--10px {
  margin-top: -10px;
}

.fc-white {
  color: #ffffff;
}

.fs-42 {
  font-size: 42px;
}

.unstyled {
  padding: 0px;
  margin: 0px;
  background: transparent;
}

/* For WebKit browsers */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.hide-scrollbar {
  scrollbar-width: none;
}

/* For IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none;
}

.charlimit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.wrapword {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

.wrapword-break {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  word-break: break-all;
}

.limit-5-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.limit-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.limit-1-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.charlimit-no-width {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mw-700 {
  max-width: 700px !important;
}
.carousel-inner {
  overflow: visible !important;
}

.w100-input,
div div div .w100-input {
  width: 100% !important;
}

.mw-250 {
  min-width: 250px !important;
}

.mw-334 {
  min-width: 334px !important;
}

.country-dropdown-option img {
  padding-bottom: 3px;
}

.country-dropdown-option span {
  margin-left: 2px;
}

.modal-overlay-blue,
.modal-overlay-grey,
.modal-overlay-grey2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.modal-overlay-blue {
  background: rgba(42, 0, 255, 0.7);
  backdrop-filter: blur(8px);
  justify-content: center;
}

.modal-overlay-grey {
  background: rgba(15, 12, 34, 0.8);
  backdrop-filter: blur(0px);
  justify-content: flex-end;
}

.modal-overlay-grey2 {
  background: rgba(15, 12, 34, 0.17);
  backdrop-filter: blur(0px);
  justify-content: flex-end;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Edge */
}

.no-select-cascade > * {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Edge */
}

.no-drag {
  pointer-events: none;
  -webkit-user-drag: none; /* For Safari */
}

.fullscreen-center {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invisible {
  opacity: 0;
}

.Toastify__toast-container {
  z-index: 10001 !important; /* Must be above modal */
}

.btn-link {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: var(--main-blue) !important;
}

.impact-chip-default {
  border-radius: 4px;
  padding: 7px 10px;
  font-weight: 700;
  height: 37px;
  width: fit-content;
}

.mb-1r {
  margin-bottom: 1rem !important;
}
