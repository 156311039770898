.preferences-sidebar {
  background-color: #f7f7f7;
  float: left;
  height: 100%;
  overflow-y: auto;
}

.preferences-main-content {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.mouses-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}
.mouse-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 88px;
  height: 88px;
  border: 2px solid transparent;
  background: transparent;
  border-radius: 0;
  border-top-left-radius: 15px;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.mouse-item__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 22px;
  height: auto;
  margin: 0 auto;
  width: 100%;
}
.mouse-item__icon img,
.mouse-item__icon svg {
  display: block;
  width: 100%;
  height: auto;
}
.mouse-item__name {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 7px;
  pointer-events: none;
}
.mouse-item:hover {
  border: 2px solid #d7d7d7;
  background: #f7f7f7;
}
.mouse-item.active {
  border: 2px solid #2a00ff;
  background: transparent;
}
.mouse-item.active:hover {
  border: 2px solid #2a00ff;
  background: transparent;
}
