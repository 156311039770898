.notification-popup-list {
  position: absolute;
  width: 358.48px;
  max-height: 700px;
  top: 43px;
  right: 0px;
  gap: 0px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 2px 0px #00000040;
  z-index: 9999;
  overflow: auto;
}

.notification-item-white,
.notification-item-grey {
  display: flex;
}

.notification-item-grey {
  padding: 20px 24px;
  border-bottom: 1px solid #d7d7d7;
  background: #f7f7f7;
  color: black;
}

.notification-item-white {
  padding: 24px 20px;
  border-bottom: 1px solid #d7d7d7;
  background: transparent;
  color: black;
}

.new-notifications {
  width: 12.31px;
  height: 12.31px;
  border: 0.5px solid #f8f8f8;
  border-radius: 100%;
  background: #2a00ff;
  position: absolute;
  top: -5px;
  right: -2px;
}
