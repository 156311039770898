.visx-group .visx-axis-tick svg text {
  color: yellow !important;
  background-color: red !important;
}

.clicked-insight-tooltip {
  width: 293.84px !important;
  height: 187.94px !important;
  border-radius: 16px;
  border: none !important;
}

.clicked-insight-tooltip line {
  display: none;
}

.insight-tooltip {
  width: 208px;
  min-height: 100px;
  padding: 18px;
  border-radius: 16px !important;
}

.fc-green {
  color: #25a93a !important;
}

.fc-red {
  color: #dd0d0d !important;
}

.fc-yellow {
  color: var(--dashboard-trend-neutral);
}

.f-satoshi {
  font-family: "satoshi" !important;
}

.f-ibm-plex-sans {
  font-family: "IBM Plex Sans", sans-serif;
}

.ls-04 {
  letter-spacing: 0.4px;
}

.lh-16 {
  line-height: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.hover:hover {
  cursor: pointer;
}

.visx-annotationlabel > line {
  display: none;
  stroke-width: 0;
}

.fc-lg {
  color: #78909c !important;
}

.fs-12 {
  font-size: 12px !important;
}

.chart-insight {
  padding: 24px;
  width: 293.83px;
  min-height: 100px;
  background: white;
  border-radius: 16px !important;
  border: none;
  box-shadow: 0px 0px 2px 0px #00000026;
  pointer-events: all;
}

.chart-insight-container {
  padding: 0px !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  min-height: 100px;
  border: none;
  background-color: transparent;
}

.lh-324 {
  line-height: 32.4px;
}

.metrics-chart > div > svg {
  overflow: visible;
}

.chart-date-tooltip {
  padding: 16px;
}

.visx-tooltip {
  z-index: 9999;
  border-radius: 16px !important;
}
