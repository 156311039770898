.FA-container {
  margin-top: 50px;
  min-width: 273px;
  width: 100%;
}

.funnel-filter-toggle-btn-active,
.funnel-filter-toggle-btn-inactive {
  width: 49px;
  height: 49px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  margin-right: 10px;
  cursor: pointer;
}

.funnel-filter-toggle-btn-active {
  background-color: var(--main-blue);
  color: white;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.funnel-filter-toggle-btn-inactive {
  background-color: transparent;
  color: black;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.behavior-toggle {
  height: 24px !important;
  width: 36px;
  float: right;
  padding: 0 !important;
  border-radius: 99px !important;
  position: relative;
}

.behavior-toggle-circle-on,
.behavior-toggle-circle-off {
  border-radius: 99px;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 6px;
  transition: left 0.1s ease-in-out;
}

.behavior-toggle-circle-off {
  background: white;
  left: 6px;
}

.behavior-toggle-circle-on {
  background: white;
  left: 18px;
}

.custom-funnel-dropdown-colours > div > div > div > div {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  overflow: none;
}

.custom-funnel-dropdown-colours * {
  border-radius: 8px;
  color: var(--main-blue) !important;
}

.custom-funnel-dropdown-colours > div > div > div > div {
  background: white !important;
}

.custom-funnel-dropdown-colours .select-selected-option {
  font-weight: 700 !important;
}

.segment-funnel-dropdown-colours > div > div > div > div {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  overflow: none;
}

.segment-funnel-dropdown-colours > div > div > div > div {
  background: white !important;
}

.segment-funnel-dropdown-colours .floating-label {
  color: var(--main-blue) !important;
}

.funnel-daterange {
  margin-top: 32px;
  border-radius: 8px;
  background-color: #7c4dff29;
  padding: 6px 9px;
}

.funnel-datepicker-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent;
  color: var(--main-blue);
  padding: 0;
  margin: 0;
  margin-top: 8px;
}

.transparency-fix > div {
  background-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}

.calendar-cutoff-fix > aside {
  width: 470px !important;
  pointer-events: none;
}

.calendar-cutoff-fix > aside > div {
  pointer-events: none;
  overflow: auto !important;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.calendar-cutoff-fix > aside > div > * {
  pointer-events: auto;
}

.disabled-filter > * {
  opacity: 0.3 !important;
  pointer-events: none !important;
}
