.darker-on-hover:hover {
  background-color: #f7f7f7;
}

.add-user-modal {
  width: 620px !important;
  height: 598px;
}

.new-user-confirm-btn {
  width: 363px;
  height: 44px;
  margin-top: 16px;
  background: var(--main-blue);
  color: white;
}

.li-gap-10 li {
  margin-bottom: 10px;
}

.role-dropdown,
.role-dropdown-disabled {
  background: white;
  border: solid 2px #000;
}
.role-dropdown .chevron-value-offset {
  color: #000;
}
.role-dropdown.dropdown-control-has-value {
  padding: 12px;
  color: #000;
  font-weight: 700;
  .chevron-value-offset {
    transform: none;
  }
}

.role-dropdown-disabled {
  height: 52px;
  width: 130px;
  padding: 12px;
  border-radius: 8px;
}
