.actions-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: 10px;
}

.actions-list .action-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding: 24px 0;
  border-bottom: 1px solid #d7d7d7;
  min-height: 92px;
  cursor: default;
}
.actions-list .action-item.has-desc {
  align-items: flex-start;
}
.actions-list .action-item .context {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  column-gap: 9px;
  row-gap: 9px;
}
.actions-list .action-item .top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  row-gap: 10px;
}
.actions-list .action-item .icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 20px;
}
.actions-list .action-item .icon i {
  font-size: 20px;
  color: var(--black-200);
}
.actions-list .action-item .label {
  font-size: 18px;
  font-family: "Satoshi";
  font-weight: 700;
  line-height: 25px;
  color: var(--black-200);
}
.actions-list .action-item .description {
  font-size: 16px;
  font-family: "Satoshi";
  font-weight: 400;
  line-height: 20px;
  color: var(--grey);
  margin-left: 30px;
}
.actions-list .action-item .btn {
  font-size: 14px;
  font-family: "Satoshi";
  font-weight: 700;
  line-height: 24px;
  padding: 8.5px 18px;
  max-width: max-content;
  border-width: 1.5px;
  transition: all 0.3s ease-in-out;
  border-color: var(--main-blue);
  color: var(--main-blue);
}
.actions-list .action-item .btn:hover {
  background-color: var(--main-blue);
  color: var(--white);
}
.insight-screenshot-section {
  max-width: max-content;
}

.lh--3 {
  transform: translateY(2px);
}

.error-element:not(:last-child) {
  margin-bottom: 10px;
}

.error-element {
  padding: 15px 20px 15px 20px;
  border-radius: 8px;
  border: 1px;
  background: #0f0c22;
  border: 1px solid #d7d7d7;
}

.goToPage-wrapper {
  margin-top: 10px;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 24px;
}
.goToPage-wrapper a {
  color: #707070;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.goToPage-wrapper a:hover {
  color: var(--main-blue);
}
