.datepicker-default-css {
  background-color: aquamarine;
  color: red;
  width: 100% !important;
  max-width: 100% !important;
}

.react-datepicker {
  font-size: 0.8rem;
  background-color: red;
  color: #000;
  border: none;
  display: inline-block;
  position: relative;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-popper {
  width: 872px !important;
  height: 604px !important;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  margin: 25px 0px;
  z-index: 9999 !important;
  pointer-events: all !important;
  position: relative;
}

.react-datepicker-popper {
  width: 464px !important;
}

.datepicker-header {
  right: 109px !important;
}

.datepicker-btn-container {
  top: 371px !important;
  right: 9px !important;
}

.react-datepicker {
  left: 45px !important;
}

.react-datepicker-popper::after {
  content: "";
  position: absolute;
  bottom: -43px;
  color: white;
}

.react-datepicker {
  position: relative;
  top: 135px;
  left: 55px;
  border: none !important;
}

.react-datepicker__day {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px !important;
  height: 40px !important;
  margin: 4px 6px !important;
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px !important;
  letter-spacing: 0px;
  text-align: center;
  color: #000000 !important;
}

.react-datepicker__day--disabled {
  color: #d3d3d3 !important;
}

.react-datepicker__day:hover {
  border-radius: 999px !important;
  background-color: #e3dff8;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 999px !important;
}

.react-datepicker__day--selected {
  border-radius: 999px !important;
  color: #ffffff !important;
  background-color: #2a00ff !important;
}

div .react-datepicker__day--selected:hover {
  color: #ffffff !important;
}
.react-datepicker__day:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.react-datepicker__month-container {
  width: 352px !important;
  height: 413px !important;
  margin-right: 55px !important;
}

.react-datepicker__navigation {
  height: 24px !important;
  width: 24px !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: black !important;
  border-style: solid;
  border-width: 2px 2px 0 0 !important;
  content: "";
  display: block;
  height: 12px !important;
  position: absolute;
  top: 6px !important;
  width: 12px !important;
}

.react-datepicker__navigation--previous {
  transform: translate(10px, -13px);
}

.react-datepicker__navigation--next {
  transform: translate(-48px, -13px);
}

.react-datepicker__current-month {
  font-family: Satoshi !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
  color: #1c1c1c !important;
  transform: translate(10px, -24px);
}

.react-datepicker__day-name {
  width: 47.5px !important;
  height: 20px !important;
  padding: 4px, 6px, 4px, 6px !important;
  gap: 10px !important;
  font-family: Satoshi;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  letter-spacing: 1.5px !important;
  text-align: center;
  color: #b5bec6 !important;
  transform: translate(6px, -4px);
}

.react-datepicker__header {
  text-align: center;
  background-color: #ffffff !important;
  border-bottom: none !important;
  position: relative;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
}

.datepicker-header {
  font-family: Satoshi;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #1c1c1c;

  position: absolute;
  top: -95px;
  right: 311px;
  display: flex;
  gap: 24px;
}

.custom-calendar-cancel-btn,
.custom-calendar-confirm-btn {
  flex-shrink: 0;
  flex-grow: 0;
  width: 200px !important;
  height: 52px;
  padding: 12px 18px;
  border-radius: 5.93px;
  border: 2px solid #2a00ff;
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.custom-calendar-cancel-btn {
  color: #2a00ff;
  background-color: #ffffff;
}

.custom-calendar-confirm-btn {
  border: 2px;
}

.custom-calendar-confirm-btn:disabled {
  color: #e8e8e8 !important;
  background: #937dff !important;
}

.datepicker-btn-container {
  position: absolute;
  top: 345px;
  right: 47px;
  display: flex;
  gap: 24px;
}

div .react-datepicker__day--in-range,
div .react-datepicker__day--in-range:hover {
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  color: black !important;
  background-color: #e3dff8 !important;
  border-radius: 0px !important;
  height: 40px !important;
  width: 52px !important;
}

div .react-datepicker__day--in-selecting-range {
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  background-color: #e3dff8 !important;
  border-radius: 0px !important;
  height: 40px !important;
  width: 52px !important;
}

div .react-datepicker__day--range-start,
div .react-datepicker__day--range-end,
div .react-datepicker__day--selecting-range-start,
div .react-datepicker__day--selecting-range-end,
div .react-datepicker__day--range-start:hover,
div .react-datepicker__day--range-end:hover {
  position: relative;
  padding: 0px !important;
  margin: 0px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 4px !important;
  margin-bottom: -4px !important;
  color: white !important;
  background-color: transparent !important;
  height: 40px !important;
  width: 40px !important;
  z-index: 89 !important;
}

div .react-datepicker__day--range-start::before,
div .react-datepicker__day--range-end::before,
div .react-datepicker__day--selecting-range-start::before,
div .react-datepicker__day--selecting-range-end::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  top: -5px;
  left: 0px !important;
  background-color: var(--main-blue);
  border-radius: 50%;
  z-index: -1 !important;
}

div .react-datepicker__day--selecting-range-start::before,
div .react-datepicker__day--selecting-range-end::before {
  left: 6px !important;
}

div .react-datepicker__day--range-start::after,
div .react-datepicker__day--range-end::after,
div .react-datepicker__day--selecting-range-start::after,
div .react-datepicker__day--selecting-range-end::after {
  content: "";
  position: absolute;
  top: -5px;
  height: 40px;
  width: 32px;
  background-color: #e3dff8;
  z-index: -999 !important;
}

div .react-datepicker__day--range-start.react-datepicker__day--range-end::after,
div .react-datepicker__day--range-end.react-datepicker__day--range-start::after,
div
  .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end::after,
div
  .react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-start::after {
  content: none !important;
  position: static;
  height: auto;
  width: auto;
  background-color: transparent;
  z-index: auto !important;
}

div .react-datepicker__day--selecting-range-end::after {
  left: 0px !important;
}
div .react-datepicker__day--range-end::after {
  left: -6px !important;
}

div .react-datepicker__day--range-start::after {
  right: -6px !important;
}
div .react-datepicker__day--selecting-range-start::after {
  right: 0px !important;
}

div
  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
div
  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 4px !important;
  margin-bottom: -4px !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  height: 40px !important;
  width: 52px !important;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range:hover {
  border-radius: 0px !important;
  background: transparent !important;
}

.react-datepicker__week {
  max-height: 48px !important;
}
