.info-greeting h4,
.info-greeting h5 {
  font-size: 24px !important;
  line-height: 32.4px;
  margin-bottom: 0;
}

.info-greeting {
  height: 64px;
}

.info-disclaimer {
  font-size: 18px !important;
  margin-top: 32px;
  height: 24px;
  margin-left: -3px;
}

.info-ul {
  margin-top: 36px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin-left: 2px;
}

.info-ul li {
  margin-bottom: 9px;
}

.info-btn {
  height: 44px;
  margin-top: 35px;
  width: 167.85px;
}

.info-btn img {
  margin-right: 10px;
  margin-left: 4px;
}
