.persona-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  color: white;
}

.personas-purple-box {
  background-color: rgba(124, 77, 255, 0.16);
  height: 530px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-bottom: 1px solid rgba(124, 77, 255, 0.16);
}

.persona-primary-separator {
  margin-right: 45px;
  border-right: 2px solid var(--main-blue);
  padding-right: 45px;
  height: 102px;
  margin-top: -4px;
}

.persona-selected-circle {
  width: 102px;
  height: 102px;
  border: 4px solid var(--main-blue);
  border-radius: 50%;
  position: absolute;
  margin-left: -6px;
  margin-top: -6px;
}

.persona-summary-container {
  width: calc(100% - 240px);
}

.persona-summary {
  height: fit-content;
  padding-top: 32px;
  padding-bottom: 45px;
  background-color: var(--off-white-two);
  border-radius: 16px;
  padding-left: calc(min(20%, 200px));
  padding-right: calc(min(20%, 200px));
}

.persona-summary-text {
  line-height: 19.2px;
  max-width: 700px;
  margin-top: 9px;
}

.persona_summary_pageLink {
  text-decoration: underline !important;
  color: black;
  position: relative;
}

.persona_summary_pageLink:hover {
  color: #2a00ff;
}

.persona_summary_pageLink::after {
  content: attr(data-url);
  position: absolute;
  bottom: -1.5em;
  left: 5;
  background: #000000;
  color: #fff;
  padding: 5px;
  border-radius: 8px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.persona_summary_pageLink:hover::after {
  opacity: 1;
}

.personas-edit {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  z-index: 500;
}

.personas-edit:hover {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  background-color: #00000080;
  opacity: 100;
}

.personas-modify-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 620px;
  height: 695px;
  overflow: visible;
}

.persona-367-width > div {
  width: 367px;
}

.persona-367 {
  width: 367px;
}

.input-limit-counter {
  position: absolute;
  right: 145px;
  background-color: white;
}

.persona-num {
  font-size: 20px;
  position: absolute;
  left: -26px;
  top: -8px;
}

.persona-container {
  position: relative;
  margin-left: 30px;
}

.key-number-container {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  width: 100%;
}

.key-number-box {
  width: fit-content;
  min-width: 155px;
  height: 102px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  border-radius: 12px;
  border: 1px solid var(--grey-three);
}

.personas-goal-card {
  display: flex;
  justify-content: space-between; /* Corrected property */
  width: 100%;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 54px;
  padding-bottom: 20px;
  border-radius: 12px;
  border: 1px solid var(--grey-three);
  margin-bottom: 10px;
}
