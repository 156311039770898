.insights-page {
  margin-left: -70px;
  margin-bottom: 100px;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-spinner {
  width: 50px;
  height: 50px;
}

.row-container {
  display: flex;
}

.row-number {
  text-align: left;
  font-size: 16px;
  padding-right: 12px;
}

.row-title {
  padding-bottom: 25px;
  flex: 1;
  font-size: 16px !important;
}

.gain-button,
.goals-button,
.users-button {
  margin-right: 10px;
  background-color: var(--projects-tab-color);
  color: var(--white);
}

.explore-button {
  padding: 5px 10px;
  border-radius: 8px !important;
  transform: translate(-76px, -8px);
}

.insights-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
}

.insights-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 140px;
}

.insights-header {
  height: 66px;
  background-color: #f5f5f5;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ccc;
}

.insights-header th {
  padding: 10px;
  text-align: left;
  background-color: #f5f5f5;
}

.insights-row {
  min-height: 77px;
  display: flex;
}

.insights-row td {
  font-weight: bold;
}

.insights-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.insights-row:has(.checked_checkbox) {
  background-color: #7c4dff29;
}

.insights-row:hover .hide-until-hover {
  opacity: 1;
}

.insights-row .hide-until-hover {
  opacity: 0;
}

.insight__status p {
  margin-right: 24px;
  margin-bottom: 24px;
}

.impact-tag.high {
  color: red;
  font-weight: bold;
}

.impact-tag.low {
  color: grey;
}

.icon {
  font-size: 20px;
  color: #707070;
}

.error-icons {
  display: flex;
  gap: 20px;
  font-size: 20px;
  max-width: 100px;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

/*Filters modal css*/

.filters-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(42, 53, 69, 0.9);
  backdrop-filter: blur(0px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.filters-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 820px;
  height: 611px;
  overflow: visible;
}

.filters-modal-container {
  background-color: #ffffff;
  border-radius: 10px;
}

.filters-modal .menu {
  width: 612px;
  margin-left: -2px;
}

.filters-modal i {
  cursor: pointer;
  margin-top: 10px;
  margin-right: 15px;
}

.filters-modal-header {
  margin-left: 105px;
}

.modal-header__title {
  margin-top: 32px;
}

.observation_types {
  margin-top: 32px;
}

.observation_types__selected {
  margin-top: 10px;
}

.high_impact__filter {
  margin-top: 36px;
}

.high_impact__filter_body {
  width: 610px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.high_impact__filter {
  margin-top: 32px;
}
.high_impact__filter .subtitle {
  margin-top: 4px;
}

.high_impact-toggle {
  height: 24px !important;
  width: 36px;
  float: right;
  padding: 0 !important;
  border-radius: 99px !important;
  margin-top: 7px;
  position: relative;
}

.high_impact-toggle-circle-on,
.high_impact-toggle-circle-off {
  border-radius: 99px;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 6px;
  transition: left 0.1s ease-in-out;
}

.high_impact-toggle-circle-off {
  background: white;
  left: 6px;
}

.high_impact-toggle-circle-on {
  background: white;
  left: 18px;
}

.filter_section_dropdowns {
  width: 610px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.insight-dropdown {
  border: 1px solid #d7d7d7 !important;
  border-radius: 8px !important;
  margin-bottom: 10px;
}

.v6insights .menu {
  margin-top: 20px !important;
}

.filter-section .dropdown-control {
  border-radius: 4px;
  border: 1px solid #d7d7d7 !important;
  color: black;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
}

.filters-modal .chevron-value-offset svg,
.filters-modal .dropdown-indicator svg {
  color: black !important;
}

.filters-modal .floating-label {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
}

.observation_types_icons {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}

.observation-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  color: #000000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid transparent;
}

.observation-icon i {
  margin-left: 15px;
  margin-bottom: 8px;
}

.observation-icon.selected {
  color: #2a00ff;
  border: 2px solid #2a00ff;
}
.v6-insights-checkbox {
  font-size: 24px;
  color: #d7d7d7;
  margin-left: 20px;
  margin-right: 20px !important;
  display: flex;
  align-items: center;
}

.checked_checkbox {
  font-size: 24px;
  color: #2a00ff;
}

.btn-curved-left {
  padding: 10px 20px;
  color: #2a00ff;
  border: 2px solid #2a00ff;
  border-radius: 81px 0px 0px 81px !important;
  font-size: 16px;
  cursor: pointer;
  height: 60px !important;
  width: 202px;
  min-width: 120px;
}

.btn-curved-right {
  height: 60px !important;
  width: 139px;
  padding: 10px 20px;
  background-color: #2a00ff;
  border: 2px solid #2a00ff;
  color: white;
  border: none;
  border-radius: 0px 81px 81px 0px !important;
  font-size: 16px;
  cursor: pointer;
}

.btn-new {
  margin-left: 6px;
  margin-top: 1px;
  padding: 5px 10px 7px 10px;
  background-color: white;
  color: #2a00ff;
  border: 2px solid var(--main-blue);
  border-radius: 4px !important;
  font-size: 14px;
  cursor: pointer;
}

.delete-insightsv6-btn-container {
  width: 100vw;
  background-color: white;
  height: 180px;
  position: fixed;
  bottom: 0;
  left: 0px;
}

.delete-insightsv6-btn {
  margin-top: 20px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 100px;
}

.no-results-container {
  width: 100%;
  background-color: rgba(42, 0, 255, 0.1);
  height: 333px;
  border-radius: 12px;
  border: 1px solid #e1d6da;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-results-container-content {
  width: 476px;
  height: 132px;
  display: flex;
}

.no-results-btn-content {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

.active-filters-indicator {
  position: relative;
  border: 2px solid var(--main-blue);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  line-height: 26px;
  color: var(--main-blue);
}

.v6-insights-filters-button {
  padding: 9px 10px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.always-placeholder {
  left: 10px;
  margin-right: 30px;
  pointer-events: none;
  color: #000000;
  font-size: 14px;
  z-index: 0;
  flex-grow: 1;
}

.count_button {
  width: 30px;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.selected-count-button.hidden {
  visibility: hidden;
}

.count_button .selected-count-button {
  border: 1px solid #d7d7d7;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  cursor: default;
  color: #290ebe;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  padding: 0px;
}

.placeholder-count-container {
  margin-top: 0px;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.insights-custom-dropdown {
  display: inline-block;
  border-radius: 4px;
  width: auto;
  min-width: 140px;
  margin-right: 10px;
  background: #ffffff;
  word-break: break-word;
}

.insights-custom-dropdown div div .dropdown-control {
  background-color: white;
  position: relative;
  width: auto;
  min-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
}

.insights-custom-dropdown
  div
  div
  .dropdown-control
  div
  .placeholder-count-container {
  margin-top: -4px !important;
}

.insights-custom-dropdown div div .dropdown-control div {
  overflow: visible;
}

.dropdown-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.observation-icon_dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  color: #000000;
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

.observation-icon_dropdown.selected {
  color: #2a00ff;
}

.observation_type_label {
  margin-bottom: 0px;
}

.observation_type_label.selected {
  color: #2a00ff;
}
