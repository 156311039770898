.input-container {
  position: relative;
}

.input-container label {
  position: absolute;
  color: #707070;
  font-size: 14px;
  transform: translateY(-50%);
  transition: 0.2s ease-out all;
  pointer-events: none;
  top: 22px;
  left: 12px;
}

.input-container label.active {
  font-weight: 400;
  color: #2a00ff;
}

.input-container textarea {
  resize: none;
}

.input-container input,
.input-container textarea {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
  border: solid 1px #d7d7d7;
  background: #fff;
  color: #707070;
  transition: 0.2s ease-out all;
  height: 48px;
}

.input-container textarea {
  height: 120px;
}

.input-container textarea.active,
.input-container input.active {
  font-weight: 500;
  color: #0f0c22;
  font-size: 16px;
  padding-top: 22px;
}

.input-container input.active {
  height: 64px;
}

.input-container textarea:focus-visible,
.input-container input:focus-visible {
  outline-color: #2a00ff;
  outline-width: 2px;
}

.custom-input-logo,
.custom-input-logo-active {
  background-color: #ededed;
  width: auto;
  margin: 8px 0;
  border-radius: 8px 0 0 8px;
  transition: 0.2s ease-out all !important;
}

.custom-input-logo-active {
  height: 64px;
  padding: 20px 6px;
}

.custom-input-logo {
  height: 49px;
  padding: 12px 6px;
}

.custom-input-logo svg,
.custom-input-logo img,
.custom-input-logo-active svg,
.custom-input-logo-active img {
  height: 18px;
  width: auto;
  margin: auto;
}

.text-input {
  background-color: var(--off-white-two);
  color: #000000 !important;
  border: none !important;
  height: 44px !important;
  border-radius: var(--standard-border-radius) !important;
  padding: 12px;
}

.input-container-logo input {
  border-radius: 0 8px 8px 0 !important;
}

.observation-text-input-container {
  width: 100% !important;
  min-width: 400px;
  margin-top: 10px;
  position: relative;
}

.disabled * {
  color: #707070 !important;
  background-color: #f7f7f7 !important;
}

.observation-text-input-container h6,
.observation-text-input-container label {
  position: absolute !important;
  top: 22px !important;
  color: #707070;
  font-size: 14px !important;
  left: 12px !important;
  transform: translateY(-50%) !important;
  transition: 0.2s ease-out all !important;
  pointer-events: none !important;
}

.observation-text-input-container h6 {
  font-style: italic;
  position: absolute !important;
  top: 50px !important;
}

.observation-text-input-container label.active {
  font-weight: 400 !important;
  color: #2a00ff;
}

.observation-text-input-container input,
.observation-text-input-container textarea {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  padding: 12px;
  font-size: 14px !important;
  border-radius: 8px !important;
  border: solid 1px #d7d7d7 !important;
  background: #fff;
  color: #707070;
  transition: 0.2s ease-out all !important;
  height: 48px !important;
}

.google-analytics-input-container input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  padding: 12px !important;
  font-size: 14px !important;
  border: solid 1px #d7d7d7 !important;
  background: #fff !important;
  color: #707070 !important;
  transition: 0.2s ease-out all !important;
  height: 48px !important;
}

.observation-text-input-container textarea {
  height: 120px !important;
}

.observation-text-input-container textarea.active,
.observation-text-input-container input.active,
.google-analytics-input-container input.active {
  font-weight: 500 !important;
  color: #0f0c22;
  font-size: 16px !important;
  padding-top: 32px !important;
}

.observation-text-input-container input.active,
.google-analytics-input-container input.active {
  height: 64px !important;
}

.observation-text-input-container textarea:focus-visible,
.observation-text-input-container input:focus-visible {
  outline-color: #2a00ff;
  outline-width: 2px;
}

.password-mismatch-box,
.input-container .text-input.password-mismatch-box:focus-visible {
  outline: 2px solid red;
  outline-offset: -1px;
  transition: outline-color 0.5s !important;
}

.password-mismatch-text-on,
.password-mismatch-text-off {
  color: red;
}

.password-mismatch-text-on {
  opacity: 1;
  transition: opacity 0.5s;
}

.password-mismatch-text-off {
  opacity: 0;
}

.signup-input-gap {
  margin-top: 16px;
}

.https {
  position: absolute;
  top: 30px;
  left: 12px;
  font-size: 16px;
  color: var(--grey);
  font-weight: 500;
}

.custom-input-checkmark {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-input-checkmark i {
  color: #25a93a;
  position: absolute;
  right: 4px;
  transform: translateY(-50%);
  font-size: 16px;
}
