.script-box {
  background-color: #707070;
  border-radius: 8px;
  white-space: pre-wrap;
  overflow-x: auto;
}

.send-email-modal {
  width: 429px;
  min-height: 182px;
}

.send-email-confirm-btn {
  width: 363px;
  height: 44px;
  margin-top: 16px;
  background: var(--main-blue);
  color: white;
}
