.project-image-name {
  color: #707070;
}

.project-image-preview {
  object-fit: contain;
  height: 24px;
  width: 24px;
}

.help-from-team {
  background-color: var(--off-white);
  padding: 16px;
  border-radius: var(--standard-border-radius);
  font-size: 14px;
  display: flex;
  align-items: center;
}

.modal-shape2 {
  width: 429px;
  min-height: 200px;
  border-radius: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background: white;
}

.x-button {
  background: white;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50% !important;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.modal-image {
  width: 72px;
  border-radius: 50px;
}

.ux-btn {
  background: var(--main-blue);
  color: white;
  margin: 32px 12px 32px 0;
}

.to-dashboard-btn {
  background: var(--blue-opacity10);
  color: var(--main-blue);
  margin: 32px 0;
}

.square-radio-btn {
  position: relative;
  border-radius: 10px;
  border: 2px solid #1f1f24;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  height: 192px;
  width: 228px;
  cursor: pointer;
}

.square-radio-btn div {
  cursor: pointer;
}

.square-radio-btn .form-check-input {
  position: absolute;
  top: -17px;
  left: 7px;
  width: 26px;
  height: 26px;
}

.square-radio-btn .form-check-input {
  border: 2px solid black;
}

.square-radio-btn .form-check-input:focus {
  box-shadow: none;
}

.square-radio-btn.active.form-check::after {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -9px;
  width: 16px;
  height: 16px;
  background-color: var(--main-blue);
  border-radius: 12px;
}

.square-radio-btn.active.form-check > :first-child {
  border: 2px solid var(--main-blue);
  background-color: white;
}

.form-check-input:active {
  filter: brightness(100%) !important;
}

.square-radio-btn input[type="radio"]:checked + label {
  border-radius: 7px;
  background: var(--main-blue);
  border-color: var(--main-blue);
  color: white;
  height: 100%;
  width: 100%;
}

.square-radio-btn.active {
  border: 2px solid var(--main-blue);
}

.copiable-input {
  transition: outline-color 0.2s ease-in-out;
}

.copiable-input:focus-within {
  outline-color: #25a93a;
}

.project-name-caption {
  font-size: 14px !important;
  color: #707070;
  margin-bottom: 8px;
}

.youtube-video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.invalid-email-text {
  margin-top: 4px;
  margin-bottom: -4px;
  font-size: 14px;
  color: red;
}

.or-text {
  font-size: 14px;
  color: #707070;
  margin: 12px 0;
}
.line {
  width: 100%;
  height: 1px;
  background: #707070;
  opacity: 0.2;
}

.google-sign-in {
  border: none;
  font-weight: 700;
  font-size: 13.34px;
  line-height: 23.71px;
  width: 100%;
  height: 50px;
  color: #ffffff;
}
.or-container {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 5px;
}

.google-sign-in-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.google-sign-in img {
  margin-right: 12px;
}

.google-sign-in:hover,
.google-sign-in:active,
.google-sign-in:focus-visible,
.google-sign-in {
  outline: none;
  border: none;
  color: #0f0c22;
  background: var(--off-white-two);
}

.auth-success-label {
  font-size: 14px;
  color: #707070;
  margin: 12px 0;
}

.create-project-continue-btn {
  width: 122.78px;
  padding-left: 19px;
}

.create-project-dropdown-section1 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 13px !important;
}

.create-project-dropdown-section2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 6px !important;
}

.industry-tag-chip {
  height: 40px;
  padding: 10px 12px;
  border-radius: 8px;
  border: 2px solid #000000;
  gap: 10px;
  margin-right: 10px;
}

.s16-medium {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.api-list,
.api-list div {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1f1f24;
}

.api-list-spacing > div {
  margin-top: 16px;
}

.api-list-spacing div p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 1.15rem;
}

.api-copy {
  width: 17.5px;
  height: 20px;
  position: absolute;
  top: -34px;
  left: 460px;
}

.provide-links-container {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.provide-links-container.active {
  display: flex !important;
  opacity: 1;
}

.provide-links-container .content-main {
  flex: 1;
  max-width: calc(100% - 252px);
  min-width: 500px;
  width: 100%;
  float: left;
}
.provide-links-container .sidebar-main {
  position: relative;
  padding-left: 32px;
  max-width: max-content;
  margin-left: auto;
  float: left;
}

.provide-links-content .create-project-footer {
  padding-bottom: 100px;
}

.payment-square-wrapper {
  max-width: max-content;
  min-height: max-content;
}
.payment-square-wrapper .payment-square {
  margin-right: 0;
  margin-top: 44px;
}

@media screen and (max-width: 1176px) {
  .provide-links-container .sidebar-main {
    padding-left: 20px;
  }
}

@media screen and (max-width: 1166px) {
  .provide-links-container.has-sidebar {
    flex-direction: column;
    align-items: stretch;
  }
  .provide-links-container .content-main {
    max-width: 505px;
    padding-left: 0;
    margin-right: auto;
  }
  .provide-links-container .sidebar-main {
    padding-left: 0;
    margin-right: auto;
  }
  .provide-links-container.has-sidebar .create-project-footer {
    padding-bottom: 32px;
  }
}
