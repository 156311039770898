.nav-line2 {
  width: 1154.449px;
  height: 1px;
  background: rgba(42, 0, 255, 0.1);
  margin-top: 12px;
}

.todo-tab-bar2 {
  height: 2px;
  margin-bottom: -13px;
  margin-top: 4px;
}
