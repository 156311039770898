.payment-modal-shape2 {
  max-width: 1332px !important;
  max-height: 1646px !important;
  height: 90%;
  width: 100%;
  border-radius: 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background: white;
  overflow: hidden !important;
}

.payment-modal-shape2-inner {
  position: relative;
  overflow: auto !important;
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.payment-container2 {
  width: 1332px !important;
  padding-left: 175px;
  overflow: hidden !important;
}

.payment-square,
.payment-square-selected {
  width: 220px;
  height: 230px;
  padding: 23px 0 23px 23px;
  margin-right: 10px;
  margin-top: 44px;
}

.payment-square {
  background: #f7f7f7;
}

.payment-square-selected {
  background: #eae2ff;
  color: #2a00ff !important;
}

.payment-square-rounded {
  border-radius: 12px 0px 0px 0px;
}

.payment-col-1 {
  border-radius: 12px 0px 0px 12px;
  width: 282px;
  max-height: 889.56px;
  padding: 23px;
  padding-top: 28px;
  padding-bottom: 33px;
  background: #f7f7f7;
  margin-right: 10.25px;
  margin-left: 8px;
  margin-top: 11.73px;
}

.payment-col-2,
.payment-col-2-selected {
  width: 220px;
  max-height: 889.56px;
  padding: 23px;
  padding-top: 28px;
  margin-right: 10.25px;
  margin-top: 11.73px;
}

.payment-col-2 {
  background: #f7f7f7;
}

.payment-col-2-selected {
  background: #7c4dff29 !important;
}

.col-selected {
  background: #7c4dff29 !important;
}

.payment-pull-tab {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 220px;
  height: 44px;
  padding: 13px 16px 13px 16px;
  border-radius: 12px 0px 0px 0px;
  margin-left: -23px;
  margin-top: -67px;
  color: white;
}

.square-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  margin-top: 16.5px;
}

.square-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0.42px;
}

.payment-btn {
  margin-top: 24px;
  height: 44px;
  width: 173px;
}

.br-4 {
  border-radius: 4px !important;
}

.reduction-box {
  width: 69px;
  height: 39px;
  right: 0;
  top: -22px;
  position: absolute;
  padding: 6px 15px 6px 15px;
  opacity: 0px;
  border: 2px solid var(--main-blue);
  color: var(--main-blue);
  background: transparent;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
}

.hover-purple-p {
  position: relative;
}

.hover-purple-p::before {
  content: "";
  position: absolute;
  z-index: 49;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #2a00ff1a;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  width: 972px !important;
  margin-left: 15px;
}

.hover-purple-p:hover::before {
  opacity: 1;
}

.info-block {
  background: #f7f7f7;
  margin-left: 5px;
  margin-right: 5px;
  width: 220px !important;
  flex: 0 0 auto;
  z-index: 48;
  justify-content: center;
  align-items: center;
  display: flex;
}

.info-block-1 {
  background: #f7f7f7;
  flex: 0 0 auto;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 24px;
  padding-right: 14px;
  width: 282px !important;
  z-index: 48;
}

.plan-headers-wrapper {
  position: fixed;
  z-index: 50;
  pointer-events: none;
}

.plan-headers {
  position: absolute;
  transition: none;
  min-width: 700px;
  pointer-events: none;
}

.plan-headers a {
  pointer-events: auto;
}

.plan-headers.scrolling:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
