.verifier-modal {
  width: 839px !important;
}

.verifier-modal-content {
  height: 100vh;
  max-height: 1142px;
  width: 839px !important;
  padding-left: 142.5px;
  padding-right: 142.5px;
  overflow: auto;
}
