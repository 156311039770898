.tab-bar-insights {
  width: 58px;
  height: 2px;
  margin-bottom: -6px;
  margin-top: -3px;
}

.m-0 {
  margin: 0 !important;
}

.insight-list-item,
.insight-list-item-active {
  border-radius: 16px !important;
  min-width: 415px;
  max-width: 415px;
  margin-bottom: 10px;
}

.insight-list-item {
  background: #f7f7f7;
  color: black;
}

.insight-list-item-active {
  background: var(--main-blue);
  color: white;
}

.insight-list-item:hover {
  background-color: #2a00ff1a !important;
}

.insight-info {
  padding-left: 75px;
}

.fs-13 {
  font-size: 13px;
}
.lh-2695 {
  line-height: 26.95px;
}

.insight-rec-card,
.insight-rule-card {
  border-radius: 8px;
  padding: 24px;
  border: 0.61px solid #e1d6da;
  max-width: 700px;
}

.insight-rec-card {
  background: #2a00ff1a;
}

.insight-rule-card {
  background: white;
}

.btn-transparent {
  box-sizing: border-box;
  background: transparent;
  color: #2a00ff;
  border: 2px solid #2a00ff;
}

.btn-grey {
  box-sizing: border-box;
  background: #f7f7f7;
  color: #0f0c22;
  border: 1px solid #d7d7d7;
}

.wrap {
  overflow-wrap: anywhere;
  hyphens: auto;
}
