.timeframe-filter {
  width: 151px;
  height: 32px;
  padding: 0px 24px 0px 24px;
  border-radius: 8px;
  background: #7c4dff29;
}

.funnel-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  margin-top: 23px;
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  border-right: none;
  height: 598px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.funnel-item {
  flex: 1;
  display: flex;
  justify-content: center;
  min-width: 150px;
}

.funnel-item-bl {
  border-top-left-radius: 16px;
}

.funnel-item-br {
  border-top-right-radius: 16px;
}

.funnel-item-border-left {
  border-left: 1px solid #d7d7d7;
}

.funnel-item-page {
  height: 98px;
  border-top: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  width: 100%;

  padding-top: 28px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  box-sizing: border-box;
  background-color: var(--off-white-two);
}

.funnel-item-sessions {
  height: 100px;
  border-right: 1px solid #d7d7d7;
  border-top: 1px solid #d7d7d7;
  border-bottom: none;
  width: 100%;
  background: white;
  padding-top: 23px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  box-sizing: border-box;
}

.funnel-item-chart {
  height: 195px;
  border-bottom: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.funnel-item-dropoff {
  height: 205px;
  border-right: 1px solid #d7d7d7;
  width: 100%;
  background: white;
  padding-top: 51px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  box-sizing: border-box;
}

.word-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.btn-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.down-btn {
  height: 50px;
  width: 50px;
  border-radius: 100% !important;
  border: 3px solid white;
  position: absolute;
  left: 50%;
  top: -25px;
  transform: translateX(-50%);
  background: var(--black-200);
  padding-left: 15px;
  padding-top: 13px;
}

.down-btn:hover {
  background: var(--main-blue);
}

.heatmap-container {
  min-height: 1000px;
  margin-top: 25.29px;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-right: none;
  overflow: hidden;
  padding-bottom: 150px;
}

.heatmap-item-page {
  height: 98px;
  border-left: 1px solid #d7d7d7;
  width: 650px;
  background: white;
  padding-top: 26px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  box-sizing: border-box;
  border-top: none;
}

.border-right {
  border-right: 1px solid #d7d7d7;
}

.heatmap-item {
  flex: 0;
  display: flex;
  width: 650px;
  border-top: 1px solid #d7d7d7;
}

.rounded-left {
  border-top-left-radius: 16px;
}

.rounded-left > div {
  border-top-left-radius: 16px;
}

.rounded-right > div {
  border-top-right-radius: 16px;
}

.rounded-right {
  border-top-right-radius: 16px;
}

.heatmap-overlay,
.heatmap-overlay-line,
.heatmap-overlay-darker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heatmap-overlay-line {
  border-left: 0.5px solid white;
  z-index: 0;
}

.heatmap-overlay {
  background-color: rgba(15, 12, 34, 0.65);
}

.heatmap-overlay-darker {
  background-color: rgba(15, 12, 34, 0.8);
}

.step_page-name {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.funnel-item-link {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.funnel-item:hover .funnel-item-link {
  opacity: 1;
  visibility: visible;
}

.path-modal-shape {
  width: 1239px !important;
  height: auto;
  max-height: 100vh;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background: white;
  padding: 50px;
}

.path-change-dropdown-fix {
  margin-left: 10px;
  position: relative;
  top: 5px;
}

.pending-condition {
  font-size: 14px !important;
  border-radius: 8px !important;
  border: solid 1px #d7d7d7 !important;
  background: var(--off-white-two);
  margin-left: 10px;
  height: 48px;
  position: relative;
  top: 5px;
  padding-top: 10px;
  padding-left: 12px;
  color: #707070;
}

.step-index-numbering {
  position: absolute;
  pointer-events: none;
  margin-top: 27px;
}

.scrolls-canvas {
  z-index: 4;
}

.clicks-canvas {
  z-index: 5;
}

.frustrations-canvas,
.hesitations-canvas,
.insights-canvas,
.mouseover-canvas,
.elements-canvas {
  pointer-events: auto;
}

/* These cannot go over 9, otherwise calendar modal will be covered */
.frustrations-canvas,
.hesitations-canvas {
  z-index: 6;
}

.insights-canvas {
  z-index: 7;
}

.mouseover-canvas,
.mousepath-canvas,
.elements-canvas {
  z-index: 8;
}

.heatmap-tooltip-1 > div > div:first-child,
.heatmap-tooltip-2 > div > div:first-child {
  font-weight: 700;
  color: var(--white);
}

.heatmap-tooltip-1 > div > div:first-child {
  font-size: 24px;
}

.heatmap-tooltip-3 > div > div:first-child {
  font-size: 16px;
}

.heatmap-tooltip-1 > div > div:nth-child(2),
.heatmap-tooltip-2 > div > div:nth-child(2) {
  font-size: 16px;
}

.heatmap-tooltip-1 > div > div:nth-child(2),
.heatmap-tooltip-2 > div > div:first-child {
  font-weight: 500;
  color: var(--white);
}

.heatmap-tooltip-2 > div > div:nth-child(2) {
  color: var(--white);
  font-weight: 700;
  text-decoration: underline;
}

.heatmap-tooltip-1 > div > div:nth-child(3),
.heatmap-tooltip-2 > div > div:nth-child(3),
.heatmap-tooltip-3 > div > div:nth-child(2) {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-two);
  width: fit-content;
  white-space: nowrap;
}

.heatmap-tooltip-3 > div > div:nth-child(2) {
  margin-top: 0px !important;
  width: fit-content;
  white-space: nowrap;
}

.heatmap-tooltip-2 > div > div:first-child {
  font-size: 14px;
  width: fit-content;
  white-space: nowrap;
}

.btn-heatmap-recording {
  height: 44px !important;
  font-size: 14px !important;
  white-space: nowrap;
  width: 193px !important;
  padding-left: 28px;
}

.step-page-container {
  min-width: 200px;
  max-width: 100%;
  padding-right: 10px;
}

.heatmap-play-btn {
  margin-left: 32px;
  margin-right: 12px;
  height: 48px;
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 12px;
}

.heatmap-goto-insights {
  height: 48px;
  min-width: 126px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
}

.no-heatmap {
  width: 100%;
  background: var(--off-white-three);
  color: black;
  border-radius: 0% !important;
  height: 200px;
  display: flex;
  flex-direction: column; /* Set flex direction to column */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.no-heatmap div {
  width: 75%;
  text-align: center;
}

.path-modal-overflow-visible {
  overflow-y: visible;
}

.path-modal-overflow-auto {
  overflow-y: auto;
}

.heatmap-bar {
  position: absolute;
  bottom: 150px;
  z-index: 10;
  left: 232px;
}
