.send-code-container {
  display: flex;
}

.sidebar-container {
  width: 100px;
}

.main-content {
  margin-top: 60px;
  margin-left: 375px;
  margin-bottom: 25px;
  padding: 20px;
  flex: 1;
}

.button-container {
  margin-top: 20px;
}

.error-message {
  color: red;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}

.send-code-card-common {
  padding: 24px;
  border-radius: 16px;
}
