.profile-sidebar {
  background-color: #f7f7f7;
  float: left;
  height: 100%;
  position: fixed;
}

.profile-main-content {
  padding-left: 340px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}

@media (max-width: 900px) {
  .profile-main-content {
    padding-left: 100px;
  }
}

.lh-2013 {
  line-height: 20.13px;
}

.lh-20 {
  line-height: 20px;
}

.lh-243 {
  line-height: 24.3px;
}

.profile-text-container p {
  margin-bottom: 0px !important;
}

.profile-width-input,
div div div .profile-width-input {
  max-width: 504px !important;
}

@media screen and (max-width: 660px) {
  .profile-width-input,
  div div div .profile-width-input {
    min-width: 300px !important;
  }
}

.profile-hr {
  margin-right: 50px;
  margin-top: 31.83px;
}

.lc-023 {
  letter-spacing: 0.23px;
}

.profile-section-open {
  display: block;
}
.profile-section-closed {
  display: none;
}

.profile-sidebar-link:hover {
  background-color: #f3f3f3 !important;
}

.custom-profile-active-border {
  box-shadow: inset 4px 0 0 0 var(--main-blue);
}

.delete-confirm-modal {
  width: 429px;
  min-height: 382px;
}

.delete-confirm-x-btn {
  background-color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.delete-width-input,
div div div .delete-width-input {
  width: 367px !important;
  min-width: 367px !important;
}

.delete-confirm-btn {
  width: 363px;
  height: 44px;
  margin-top: 32px;
  background: var(--main-blue);
  color: white;
}

.extend-left {
  position: relative;
  left: -50.36px;
  padding-left: 50.36px;
}

.disabled-input {
  background-color: #f7f7f7 !important;
  color: #707070 !important;
}

div div div .disabled-input {
  background-color: #f7f7f7 !important;
  color: #707070 !important;
}

.profile-pfp-image-preview {
  object-fit: contain;
  height: 40px;
  width: 40px;
  padding: 0;
}

.change-pfp-btn-profile {
  display: flex;
  color: black;
  background: none;
  width: 100%;
  flex-direction: row;
  padding: 0;
  align-items: center;
}

.change-pfp-btn-profile .column-fixed {
  width: 54px;
}

.change-pfp-btn-profile .column-flex {
  flex-grow: 1;
  text-align: left;
}

.p-m0 {
  margin: 0px !important;
}
