.manage-projects-section-header {
  margin-top: 50px;
}

.mp-btn-fix {
  margin-left: -2px;
}

.mp-ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
