.story-page-header {
  padding: 21px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.story-page-url {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#story-scroll-container {
  overflow-x: auto !important;
  display: flex !important;
  white-space: nowrap !important;
}

.generating-stories-tooltip {
  position: absolute;
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 1);
  color: #2a00ff;
  font: 700 13px Manrope;
  padding: 10px 18px;
  z-index: 50;
  width: 169px;
  height: 44px;
}

.story-wide-overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(15, 12, 34, 0.65);
}

#story-selector-area {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}

.story-selector-card {
  padding: 20px;
  border-radius: 8px;
  position: absolute;
  width: 220px;
  height: max-content;
  z-index: 12;
  background-color: white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.leader-line {
  z-index: 10;
}

.story-action-card {
  border-radius: 8px;
  position: absolute;
  z-index: 13;
  background-color: white;
  padding: 10px 18px;
  transform: translate(-50%, 0%);
  opacity: 0;
  width: 300px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.story-full-opacity {
  opacity: 1 !important;
  z-index: 20 !important;
}

.story-top-layer,
.story-top-layer > * {
  z-index: 50 !important;
}
