.user-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.user-card-row {
  display: flex;
}

.user-card-row-center {
  display: flex;
  display: flex;
  justify-content: center;
}

.user-card {
  background: var(--off-white);
  height: 200px;
  width: 300px;
  border-radius: 16px;
  padding: 20px;
}

.user-pfp {
  display: flex;
  justify-content: center;
  min-width: 70px;
}

.user-pfp img {
  max-width: 70px;
  height: 70px;
}

.user-card .user-info-container,
.appa-card .user-info-container,
.appa-workspace-card .user-info-container {
  height: 70px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.user-card .user-info,
.appa-card .user-info,
.appa-workspace-card .user-info {
  max-width: 160px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
