.notification-on {
  background: var(--main-blue);
}

.notification-off {
  background: white;
  box-shadow: inset 0 0 0 3px black;
}

.notification-toggle {
  margin-right: 75.48px;
  height: 24px !important;
  width: 36px;
  float: right;
  padding: 0 !important;
  border-radius: 99px !important;
  margin-top: 7px;
  position: relative;
}

.notification-toggle-circle-on,
.notification-toggle-circle-off {
  border-radius: 99px;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 6px;
  transition: left 0.1s ease-in-out;
}

.notification-toggle-circle-off {
  background: black;
  left: 6px;
}

.notification-toggle-circle-on {
  background: white;
  left: 18px;
}
