.scale-to-33 {
  transform: scale(0.33);
}

.fixit-loading {
  width: 80vw;
  height: 642.37px;
  background: #0f0c22;
  border-radius: 9.8px;
}

.fixit-progress .progress {
  height: 10px !important;
  background-color: #ffffff33 !important;

  width: 300px !important;
  margin-bottom: 0px !important;
  border-radius: 89px !important;
}

.fixit-progress .progress-bar {
  background-color: white !important;
  border-radius: 89px !important;
}

.change-details {
  width: 533.48px;
  border: 0.61px solid #e1d6da;
  padding: 24px;
  padding-bottom: 0px;
  border-radius: 8px;
}
