.progress {
  height: 3px;
  margin-bottom: 48px;
}

.progress-bar {
  background: var(--main-blue);
}

.carousel-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}
