.insight-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(55, 53, 69, 0.9);
  backdrop-filter: blur(0px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
}

.insight-modal {
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  left: 100px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 0px;
  z-index: 1001;
  height: calc(100%-70px);
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-spinner {
  width: 50px;
  height: 50px;
}

.insight-modal-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-left,
.modal-right {
  overflow-y: auto;
  height: 100%;
}

.modal-right {
  position: fixed !important;
  flex-grow: 1;
  padding: 100px 32px;
  background-color: var(--off-white-two);
  min-width: 350px;
  width: 33%;
  right: 0px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modal-right::-webkit-scrollbar {
  display: none;
}

.modal-left {
  flex-grow: 3;
  padding: 26px 100px;
}

.insight-modal-content::-webkit-scrollbar {
  display: none;
}

.insight-rec-card {
  margin-bottom: 32px;
  padding: 24px;
}

.insight-modal-content::-webkit-scrollbar {
  display: none;
}

.insight-rec-card {
  margin-bottom: 32px;
  padding: 24px;
}

@media (max-width: 1380px) {
  .modal-right {
    min-width: 190px !important;
    width: 100px !important;
  }

  .hide-under-1380,
  .hide-under-1380 > * {
    display: none;
  }

  .show-under-1380 {
    display: block !important;
  }

  .action-button {
    padding: 10px !important;
    transition-duration: 0ms !important;
  }
}

.impact-heading {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 9.9px;
}

.modal-right h4 {
  font-size: 18px !important;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal-right-subtext {
  font-size: 16px;
  margin-top: 10px !important;
}

.close-icon {
  position: absolute;
  top: 26px;
  right: 20px;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.high-impact-button {
  background-color: var(--dashboard-trend-negative);
  color: var(--white);
  font-weight: 700;
  height: 34px;
  white-space: nowrap;
  margin-bottom: 20px;
  padding: 7px 10px;
  border-radius: 4px !important;
}

.modal-left h4 {
  font-size: 18px !important;
  font-weight: 700;
  margin-bottom: 0px !important;
}

.modal-left p {
  font-size: 16px;
  margin-bottom: 0px;
}

.observation-type {
  margin-right: 32px;
  padding-right: 32px;
}

.has-border {
  border-right: 1px solid #d7d7d7;
}

.text-color {
  color: #0f0c22;
}

.observation-icons {
  margin-top: 10px;
  min-width: 146px;
}

.icon-item {
  margin-right: 34px;
  font-size: 20px;
}

.severity-section,
.fix-ease-section {
  margin-right: 20px;
}

.details-toggle {
  cursor: pointer;
}

.chevron-icon {
  flex-grow: 1;
  color: #0f0c22;
  font-size: 24px;
}

.action-item p {
  margin-top: 8px;
}

.action-header {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.action-header i {
  margin-right: 8px;
  font-size: 1.2em;
}

.action-header h5 {
  margin: 0;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-header h4,
.modal-header p {
  max-width: 700px;
}

.modal-header-top {
  margin-bottom: 50px;
  width: 100%;
  justify-content: space-between;
}

.insight-number {
  color: #707070;
  font-size: 16px;
  width: 100px;
  text-align: left;
}

.icon-margin-right {
  margin-right: 10px;
  font-size: 18px;
}

.impact-section {
  background-color: #e1d6da !important;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.impact-section h3 {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.impact-section p {
  margin: 5px 0;
  line-height: 1.5;
}

.observation-section {
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #333;
}

.observation-section > div {
  flex: 1;
}

.chart-section {
  width: 700px;
  margin-bottom: -70px;
}

.icon-list {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.divider {
  width: 100%;
  border: none;
  border-top: 1px solid #3f3e3e;
  height: 60%;
  background-color: #e0e0e0;
  margin: 0px 0px;
}

.verticalLine {
  border-left: 1px solid lightgrey;
  height: 50px;
  margin: 5px;
}

.details-section {
  background-color: #f8f8f8;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 14px;
  color: #333;
}

.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
}

.details-header i {
  margin-right: 8px;
  font-size: 1.2em;
}

.code-snippet {
  background: var(--black-200);
  border: 1px solid #d7d7d7;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  font-size: 16px;
  font-weight: 700;
}

.code-snippet div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--white);
  max-width: 475px;
  font-size: 16px;
  font-weight: 700;
}

.details-paragraph {
  font-weight: 500;
  font-size: 15px;
}

.recommendations-card {
  margin-top: 20px;
  margin-bottom: 24px;
  padding-bottom: 28.94px;
}

.recommendations-icon {
  margin-right: 8px;
  font-size: 16px;
  color: #333;
}

.recommendations-text {
  margin-top: 10px;
  max-width: 620px;
  white-space: pre-wrap;
  margin-bottom: 4.94px;
}

.not-die-stupid-card {
  margin-bottom: 30px;
  padding-bottom: 0px;
}

.not-die-icon {
  margin-right: 8px;
  font-size: 16px;
  color: #333;
}

.not-die-text {
  margin-top: 10px;
  width: 620px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-bottom: 4.94px;
}

.code-actions {
  display: flex;
  gap: 10px;
}

.code-actions i {
  cursor: pointer;
  font-size: 1em;
  color: var(--white);
}

.action-icon {
  margin-right: 8px;
  align-self: flex-start;
  width: 20px;
  padding-top: 2px;
}

.action-button {
  margin-top: 16px;
  margin-bottom: 24px !important;
  height: 44px;
  border: 2px solid #2a00ff;
  padding: 10px 18px;
}

.v6insights-error-icons-modal {
  display: flex;
  gap: 20px;
  font-size: 20px;
  flex-wrap: wrap;
  padding-top: 15px;
}

.v6insights-ose-section {
  display: flex;
  background-color: var(--off-white-two);
  border: 1px solid #e1d6da;
  width: 700px;
  padding: 24px;
  border-radius: 12px;
}
