.highlight-modal {
  max-width: 79.7vw;
  border-radius: 20px;
  padding: 24px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.highlight-modal div {
  margin-top: 16px;
  margin-left: 8px;
  margin-right: 8px;
}

.highlight-modal div h2,
.highlight-modal div h5 {
  color: #1f1f24;
  font-weight: 500;
}

.highlight-modal div h2 {
  font-size: 22px;
}

.highlight-modal div h5 {
  font-size: 14px;
}

.highlight-modal div h5 text {
  color: #e4411d;
  font-weight: 700;
}

.highlight-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.highlight-button-holder button {
  padding-top: 0;
  padding-bottom: 0;
  height: 44px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--medium-blue);
}

.highlight-button-holder button h5 {
  color: white;
  margin-bottom: 0;
  margin-right: 11.5px;
}

.highlight-close-button {
  background: none;
  padding: 0;
}

.new-highlight-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  left: -15px;
  width: 32px;
  height: 32px;
  top: -15px;
  border: none;
}

.new-highlight-close-button,
.new-highlight-close-button:hover {
  background-color: #e4411d;
}

.new-highlight-close-button img {
  -webkit-user-drag: none;
}

.new-highlight {
  border: 3px solid #e4411d;
  background-color: #ff000014;
  width: 100%;
  margin: 0 !important;
  height: 100%;
}

.insight-input {
  width: 100% !important;
  max-width: 100% !important;
}

@media screen and (max-width: 985px) {
  .insight-input {
    width: 300px !important;
    max-width: 300px !important;
  }
}

@media screen and (max-width: 750px) {
  .insight-input {
    width: 250px !important;
    max-width: 250px !important;
  }
}

.insight-delete-modal {
  height: 200px;
  width: 100px;
}

.insight-delete-button-height button {
  height: 41px !important;
}
