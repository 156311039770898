.disclaimer {
  color: #707070;
  padding: 16px;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
  margin-top: 24px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-self: start;
}

.flex-col {
  max-width: min-content;
}

.tag {
  padding: 10px 12px;
  margin-left: -10px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 8px;
  height: 44px;
}

.active-tag {
  border: 2px solid #2a00ff;
  background: rgba(42, 0, 255, 0.1);
  color: #2a00ff;
}

.inactive-tag {
  border: 2px solid black;
  background: white;
  color: black;
}

.txt-g {
  color: #707070;
}

.txt-b {
  color: #06152b;
}

.half-width,
div div div .half-width {
  width: 241.5px !important;
}

.full-width,
div div div .full-width {
  width: 511px !important;
  /* This gets adjusted to 490 */
}

.input-365px,
div div div .input-365px {
  width: 347px !important;
  /* This gets adjusted to 365 */
}

.w-122px {
  /* This gets adjusted to 122 */
  min-width: 143px !important;
  max-width: 143px !important;
}

.phone-country-dynamic-box,
.phone-country-dynamic-box-expanded {
  border-radius: 8px;
  padding-top: 3px;
}

.buttons-offset {
  transform: translateY(29px) translateX(11px);
}

.check {
  width: 14px;
  height: 16px;
  position: absolute;
  top: -41px;
  left: 469px;
}

.check-phone {
  position: absolute;
  left: 316px !important;
}

.check-half-width-offset {
  position: absolute;
  left: 202px !important;
}

.label-hover:hover {
  cursor: pointer;
}

.id-scan-upload-box {
  width: 504px;
  height: 282px;
  border-radius: 8px;
  border: 1.48px solid #000000;
}

.id-scan-upload-box:hover {
  cursor: pointer;
}

.id-scan-upload-box div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.skills-custom-height {
  height: 1500px;
  padding-left: 10px;
}

.onboard-modal {
  width: 429px;
  min-height: 309.74px;
}

.onboard-btn1,
.onboard-btn2 {
  font-size: 13.34px;
}

.onboard-btn1 {
  width: 225px;
}

.onboard-btn2 {
  width: 163px;
}

.onboard-modal-image {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  object-fit: contain;
}

/**
Dropdown section
 */
.no-margins-or-padding .dropdown-section {
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 50px;
}

.no-margins-or-padding .dropdown-section .dropdown-control,
.no-margins-or-padding .dropdown-control {
  margin-bottom: 0 !important;
  padding: 12px 12px 10px 12px;
  border-radius: 8px;
  background: white;
  color: #707070;
  border: 1px solid #d7d7d7;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
}

.no-margins-or-padding .dropdown-section .dropdown-control-has-value,
.no-margins-or-padding .dropdown-control-has-value {
  padding: 30px 12px 8px 12px;
  color: black;
}

.no-margins-or-padding .dropdown-control-has-value .floating-label {
  font-size: 14px;
  font-weight: 400;
  color: var(--main-blue) !important;
}
.no-margins-or-padding .dropdown-control-has-value-chip {
  padding: 12px 12px 2px 12px;
  color: black !important;
}

.no-margins-or-padding .dropdown-control:hover,
.no-margins-or-padding .dropdown-control-has-value:hover,
.no-margins-or-padding .dropdown-control-has-value-chip,
.no-margins-or-padding .dropdown-control-has-value-chip:hover {
  cursor: pointer;
}

.no-margins-or-padding .floating-label {
  color: #707070;
  font-size: 14px;
  position: absolute;
  top: 0;
  margin-top: 12px;
}

.no-margins-or-padding .dropdown-section .chevron-value-offset,
.no-margins-or-padding .chevron-value-offset {
  transform: translateY(-10px);
}

.no-margins-or-padding .dropdown-section .menu,
.no-margins-or-padding .menu {
  margin-top: 10px;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #eee;
  background: white;
  margin-bottom: 10px;
}

.no-margins-or-padding .menu > div {
  max-height: 200px;
}

.no-margins-or-padding .dropdown-section .option,
.no-margins-or-padding .option {
  font-size: 14px;
  font-weight: 500;
  border: 5px;
  border-radius: 10px;
  padding: 12px 8px;
  align-items: center;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.no-margins-or-padding .option:hover {
  background: var(--blue-opacity10);
  cursor: pointer;
}

.no-margins-or-padding {
  margin-bottom: 200px;
  height: auto;
}

.ml-0,
.no-margins-or-padding .row {
  margin-left: 0 !important;
}

.no-margins-or-padding .row > .col:first-child,
.no-margins-or-padding .row > .col-6,
.no-margins-or-padding .row > div:first-child {
  padding-left: 0 !important;
}

.onboard-custom-dropdown-offset-side {
  margin-left: -0.7rem;
}

.onboard-custom-text-offset {
  margin-left: -0.7rem;
}

.onboard-custom-input-offset {
  margin-left: -20px;
}

.loom-url-width-fix {
  width: 115px !important;
}

.lh-189 {
  line-height: 18.9px;
}

.wait-for-validate-modal {
  background: rgba(48, 48, 63, 1) !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
