.reports-table {
  min-width: 100%;
  font-weight: 700;
}

.reports-header th {
  font-weight: 500 !important;
}

.report-btn {
  min-height: 44px;
  min-width: 20px;
  background: var(--main-blue);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hide-text-on-smaller-screens,
.hide-text-on-smaller-screens2 {
  display: inline-block;
  text-align: center;
}

@media screen and (max-width: 1120px) {
  .hide-text-on-smaller-screens {
    display: none;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .hide-text-on-smaller-screens2 {
    display: none;
    text-align: center;
  }
}

.text-overflow-handler {
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
}
