.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.sign-in-container {
  box-sizing: border-box;
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
  flex-wrap: wrap;
}

.motto,
.form {
  height: 100%;
  overflow: visible;
}

.motto {
  background-color: var(--standard-font-color);
  top: 0;
  left: 0;
  width: 42vw;
  position: fixed;
  display: block;
}

.form {
  position: relative;
  float: right;
  width: 58vw;
  margin-left: 42vw;
}

.motto-text {
  position: absolute;
  z-index: 2;
  color: #ffffff;
  font-size: 28px;
  line-height: 33.6px;
  overflow-wrap: break-word;
  position: relative;
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 100%;
}

.form-text {
  top: 238.38px;

  width: 505px;

  overflow: visible;
  margin: auto;
  position: relative;
}

.signin-header {
  font-size: 28px;
  color: var(--standard-font-color);
  font-weight: 500;
  line-height: 37.8px;
  font-weight: 500;
}

.google-sign-in,
.email-auth,
.email-auth-active {
  font-weight: 700;
  font-size: 13.34px;
  line-height: 23.71px;
  height: 50px;
  color: #ffffff;
}

.w-373px {
  width: 373.02px !important;
}

.email-auth-text,
.google-sign-in-text,
.sign-up {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.email-auth img,
.email-auth-active img {
  margin-left: 12px;
}

.google-sign-in img {
  margin-right: 12px;
}

.google-sign-in {
  color: #0f0c22;
  background: var(--off-white-two);
}

.email-auth {
  background: #c8c8c8;
}

.email-auth-active {
  background: var(--projects-tab-color-active);
}

.forgot-password {
  color: #707070;
  font-size: 16px;
  font-weight: 400;
  float: right;
  margin: 0 0 20px 0;
}

.sign-up {
  color: #707070;
  margin-top: 50px;
  font-size: 16px;
}

@media (max-width: 1100px) {
  .motto {
    top: 0;
    position: absolute;
    height: 180px;
    width: 100vw !important;
  }

  .form {
    position: relative;
    width: 100vw !important;
  }

  .form-text,
  .motto-text,
  .form,
  .motto {
    margin: auto;
    left: initial;
    transform: initial;
  }
}

@media (max-width: 600px) {
  .motto,
  .motto-text {
    display: none;
  }
}

.bad-credentials {
  color: red;
  font-size: 16px;
  font-weight: 400;
  float: left;
  margin: 0 0 20px 0;
  width: 220px;
}

.resetlink {
  color: forestgreen !important;
}

.remove-input-restrictions {
  min-width: 0;
  width: 100% !important;
}

.fw-900 {
  font-weight: 900;
}

.motto-vertical {
  top: 12.9vh;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.motto-horizontal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.v2-login-width,
div div div .v2-login-width {
  min-width: 370px !important;
  max-width: 505px !important;
  width: 100%;
}

.font-redhat {
  font-family: "Red Hat Display", sans-serif;
}

.err-msg {
  color: red;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0 10px;
}

.strength-bar-fix > div > div > div {
  height: 4px !important;
  border-radius: 6px;
}

.validPass {
  font-weight: 700;
  color: var(--projects-tab-color);
  text-decoration: line-through;
  padding-left: 15px;
}

.invalidPass {
  font-weight: 400;
  color: var(--grey);
  padding-left: 28px;
}

.eye {
  position: absolute;
  top: -41px;
  left: 439px;
}

.eye-slash {
  position: absolute;
  top: -41px;
  left: 438px;
}
