.left-half {
  margin-top: 37px;
  width: 610px;
  margin-left: 86px;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.fs-16 {
  font-size: 16px;
}

.right-half {
  position: relative;
  top: 0;
  margin-top: 37px;
  width: 335px;
  margin-right: calc(44px - 16px); /* Scrollbar width */
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 16px;
}

@media (max-width: 1389px) {
  .right-half {
    float: left;
    margin-left: 86px;
  }
}

.offerbtn-container {
  display: flex;
}

.offerbtn-container .btn1 {
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #000000;
  border-radius: 8px 0 0 8px !important;
  border-right: none;
}

.offerbtn-container .btn2 {
  margin-left: -5px;
  border-radius: 8px;
  background: #2a00ff;
}

.offerbtn-container .btn2 img {
  margin-left: 10px;
}

.clear-fix {
  clear: both;
}

.modal-shape-2 {
  max-width: 1260px;
  min-width: 550px;
  min-height: 730px;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow-y: scroll;
  max-height: calc(100% - 40px);
}

.rounded-iframe {
  width: 610px;
  height: 307px;
  border-radius: 10px;
}

.black-btn {
  background-color: #081833;
  color: white;
  border-radius: 8px;
}

.custom-tooltip i {
  font-size: 20px;
}
