.checklist-body {
  margin-top: 50px;
}
.checklist-body div h2,
.checklist-body div h4,
.checklist-body div h3 {
  font-size: 24px;
  font-weight: 700;
  color: #1c1c1c;
}

.checklist-body div h4 {
  margin-top: 27px;
  font-size: 20px;
}

.checklist-body div h5 {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #0f0c22;
}

.checklist-body div h2 {
  margin-top: 16px;
  font-size: 18px;
}
.checklist-body div ul {
  margin-top: 11px;
}
.checklist-body div ul li {
  font-size: 16px;
  font-weight: 500;
  color: #0f0c22;
}
