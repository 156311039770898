.exit-button {
  width: 8%;
  height: 40px;
  margin-top: 27%;
  padding: 0 0 0 0;
  background-color: white;
  border-radius: 7px 0 0 7px !important;
  border: none;
}

.status-circle {
  width: 8px;
  height: 8px;
  background: #2ac843;
  color: white;
  border-radius: 50%;
  z-index: 100;
  margin-right: 14px;
}

.exit-button:active {
  background-color: white !important;
}

.exit-button:hover {
  background-color: white;
}

.w-100p {
  width: 100%;
}

.message {
  max-width: 690px;
  padding: 20px;
  font-weight: 500;
  font-size: 16px;
  overflow-wrap: break-word;
}

.message-reply > * {
  max-width: 650px;
  padding: 20px;
  font-weight: 500;
  font-size: 16px;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 16px;
}

.message-reply-text > * {
  max-width: 650px;
  font-weight: 500;
  font-size: 16px;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-container:not(:last-child) {
  margin-top: 12px !important;
}

.send-chatbar-media-container {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
}

.send-chatbar-media-container .preview-div {
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 9px;
  background-color: #ececec;
  width: 40.6px;
  height: 52px;
}

.media-recipient .preview-div,
.media-recipient .information-div {
  background-color: white;
  color: black;
}

.media-recipient .information-div h3 {
  color: #333333;
}

.media-author .preview-div,
.media-author .information-div .a,
.media-author .information-div {
  background-color: #2a7cff !important;
  color: white;
}

.media-author .information-div h3 {
  color: #ececec;
}

/*
Note: These are used in chatbar.js conditionally.
*/
.no-url-author {
  color: #ececec !important;
}

.no-url-recipient {
  color: black !important;
}

.send-chatbar-media-container .information-div {
  margin-left: 5.6px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  height: 52px;
  padding: 8px 14px 10px 14px;
}

.send-chatbar-media-container .information-div a {
  user-select: none;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}

.send-chatbar-media-container .information-div h3 {
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
}

.send-chatbar-media-container .preview-div img {
  border-radius: 9px;
  max-width: 28px;
  max-height: 28px;
  width: auto;
  height: auto;
}

.send-chatbar-image-container-text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 16px;
  border-radius: 0 0 9px 9px;
  background: rgba(12, 12, 12, 0.14);
}
.send-chatbar-image-container-text h5 {
  font-size: 12px !important;
  margin-bottom: 0 !important;
  font-weight: 500;
  color: #333333;
}
.send-chatbar-image-container {
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 9px;
  margin: 11px 5px 2px 0;
  background-color: #ececec;
}

.send-chatbar-image-container img {
  width: 54px;
  height: 54px;
  border-radius: 7.83px;
}

.send-chatbar-image-container button {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 16px;
  width: 16px;
  padding: 0;
  border: none;
  border-radius: 4.5px;
  background-color: transparent;
  color: transparent;
  font-size: 10px;
}

.send-chatbar-image-container button:hover {
  background-color: red;
  color: white;
}

.send-chatbar,
.send-chatbar-active {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  border-radius: 16px;
  padding: 22px;
  transition: all 0.3s;
}

.send-chatbar-active {
  height: 228px;
}

.send-chatbar-input {
  resize: none;
  overflow: auto;
  word-break: break-word;
  padding-left: 13px;
  width: 90%;
  height: 100%;
  border: none;
  background-color: transparent;
}

.send-chatbar-input::-webkit-scrollbar {
  display: none;
}

.send-chatbar-input:focus-visible {
  outline: none;
}

.send-chatbar-button {
  height: 10%;
  border: none;
  background-color: transparent;
  color: var(--main-blue);
  padding-right: 10px;
  z-index: 999;
}

.send-chatbar-button2 {
  height: 50px;
  width: 117px;
  border: none;
  background-color: var(--main-blue);
  color: white;
  z-index: 999;
  padding-left: 23px;
}

.send-chatbar-button2:disabled {
  background-color: #d7d7d7;
}

.send-chatbar-button:disabled {
  color: #1c1c1c33;
}

.send-chatbar-button:active {
  background-color: transparent;
}

.send-chatbar-button:hover {
  background-color: transparent;
}

.message-recipient {
  background-color: #ececec;
  border-radius: 15px;
}

.message-author {
  background-color: #0062fe;
  border-radius: 15px;
  color: white;
}

.chat-bubble-image {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.chat-title {
  font-size: 14px;
  width: 296px;
  overflow-wrap: break-word;
}

.chat-description {
  font-size: 12px;
}

.file-drop-chat-overlay {
  color: rgb(255, 255, 255);
  background: rgba(86, 145, 255, 0.5);
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1000;
}

.file-drop-full-page-overlay__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: bold;
}

.modal-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.modal-image-container img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.chat-box-container {
  width: 268px !important;
  margin-left: 5px;
}

.chat-box-container .active {
  width: 268px !important;
  height: 66px;
}

.chat-box-container input.active {
  font-weight: 500 !important;
  padding-top: 32px !important;
  height: 66px !important;
}

.chat-box-container .text-input {
  height: auto !important;
}

.chat-box-container label {
  position: absolute !important;
  top: 30px !important;
  color: #707070;
  font-size: 14px !important;
  left: 12px !important;
  transform: translateY(-50%) !important;
  transition: 0.2s ease-out all !important;
  pointer-events: none !important;
}

.chat-box-container label.active {
  font-weight: 400;
  color: #2a00ff;
  padding-top: 16px;
}

.chatbox-input {
  width: 650px;
  resize: none;
  overflow: auto;
  border: none;
  outline: none;
  font-family: "satoshi" !important;
  font-size: 16px;
  line-height: 21.6px;
  font-weight: 500;
  transition: all 0.3s;
  color: #707070;
  max-height: 7.5em;
  overflow-y: auto;
  padding: 0;
  border: 0;
}

.truncate-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 40px auto;
}

.bouncing-loader > div {
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 50%;
  background-color: #2a00ff;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.hover-box {
  border: 1px solid #d7d7d7;
  background: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border-radius: 6px;
  transition: opacity 0.3s;
  position: absolute;
  right: -10px;
  top: -10px;
}

.hover-box .dots span {
  display: block;
  width: 3.75px;
  height: 3.75px;
  background-color: black;
  margin: 3px;
  border-radius: 99%;
}

.message-options,
.message-options-flip {
  position: absolute;
  width: 244.59px;
  max-height: 156px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 0px #00000026;
}

.message-options {
  right: -224.59px;
}

.message-options-flip {
  right: -10px;
}

.message-options-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.message-options-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.message-options-item {
  width: 100%;
  height: 52px;
  padding: 18px;
  background-color: #ffffff;
}

.message-options-item:hover {
  background-color: #f7f7f7;
}
