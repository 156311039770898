.observation-button-white {
  color: #000;
  background-color: #fff;
  border-radius: 6px;
  border: 1.5px solid var(--bs-gray-200);
}

.report-menu-item {
  justify-content: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rmiactive {
  border-left: 4px solid var(--main-blue);
}

.report-menu-item:first-child {
  margin-top: 36px;
  max-width: 100px;
}

.report-menu-item a {
  padding-left: 32px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100% !important;
}
.rmiactive a {
  padding-left: 28px !important;
}

.report-menu-item a:hover {
  background-color: #2a00ff1a !important;
}

.report-menu-item-container h6,
.report-menu-item-container h5,
.report-menu-item-container h4 {
  font-weight: 500;
  color: #0f0c22;
}

.report-menu-item-container h4 {
  font-size: 16px;
}

.report-menu-item-container-active h4,
.report-menu-item-container-active h5 {
  color: #2a00ff;
}

.report-menu-item-container-active h4 {
  font-size: 16px;
  font-weight: 700;
}

.report-menu-item-button {
  background-color: transparent;
  color: #0f0c22 !important;
  border: none;
  width: fit-content;
  display: flex;
  padding: 0;
}

.rmibactive {
  font-weight: 700;
  color: #2a00ff !important;
}

.report-menu-item-button:hover {
  background-color: transparent !important;
}
.search-bar {
  padding-left: 0 !important;
}
.search-bar:focus-visible {
  outline: none;
}

.hidden-scrollbar div::-webkit-scrollbar {
  display: none;
}

.checklist-page-container {
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checklist-page-container h6 {
  margin-bottom: 0;
  margin-left: 16.5px;
  font-size: 16px;
  font-weight: 700;
  color: #0f0c22;
}

.checklist-page-container div img {
  height: 20px;
  width: auto;
}

.checklist-page-item:first-child {
  margin-top: 36px;
}

.checklist-page-item a {
  margin-top: 6px;
  margin-bottom: 6px;
  height: 100% !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.checklist-page-item button h5,
.checklist-page-item button h4 {
  border-bottom: 0.5px solid transparent;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #0f0c22;
  margin-left: 66px;
  font-size: 14px !important;
  font-weight: 700;
}

.checklist-page-item button h5 {
  margin-top: 9px;
  color: #0f0c22;
  margin-left: 76px;
  font-weight: 500;
}

.checklist-page-item button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.checklist-page-item button,
.checklist-page-item button:focus-visible,
.checklist-page-item button:active,
.checklist-page-item button:hover {
  outline: none;
  border: none;
  background-color: transparent !important;
}

.checklist-page-item-active {
  border-left: 4px solid var(--main-blue);
}

.checklist-page-item-active a {
  padding-left: 16px !important;
}

.checklist-page-item-active div h6,
.checklist-page-item-active button h5,
.checklist-page-item-active button h4 {
  font-weight: 700;
  color: #2a00ff;
}

.checklist-page-item-active button h4 {
  border-bottom: 0.5px solid #2a00ff;
}

.checklist-page-item-active button h5 {
  font-weight: 500;
}

.tab-item-text-active-blue {
  font-weight: 700;
  color: #2a00ff !important;
}

.insights-or-checklist-dynamic-padding {
  padding-left: 275px;
}

.subpoint h4,
.subpoint h5 {
  color: #0f0c22 !important;
  border-bottom: 0.5px solid transparent !important;
}

.subpoint-active h4,
.subpoint-active h5 {
  color: #2a00ff !important;
  border-bottom: 0.5px solid transparent !important;
}

.subpoint-active h4 {
  border-bottom: 0.5px solid #2a00ff !important;
}

@media screen and (max-width: 1191px) {
  .insights-or-checklist-dynamic-padding {
    padding-left: 220px;
  }
}

@media screen and (max-width: 898px) {
  .insights-or-checklist-dynamic-padding {
    padding-left: 170px;
  }
}

@media screen and (max-width: 802px) {
  .insights-or-checklist-dynamic-padding {
    padding-left: 170px;
  }
}

@media screen and (max-width: 704px) {
  .insights-or-checklist-dynamic-padding {
    padding-left: 155px;
  }
}
