.summary-info-card,
.summary-info-card2 {
  background-color: #f7f7f7;
  border-radius: 16px;
  height: 112.15px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 21.5px;
}

.summary-info-card {
  width: 129px;
}

.summary-info-card2 {
  width: 240px;
  min-width: 240px;
}

.row-TL {
  align-items: flex-start;
  justify-content: flex-start;
}

.summary-size-fix {
  width: 301px !important;
  min-width: 301px !important;
}
