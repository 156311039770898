.customnavbar-item {
  background-color: transparent !important;
  border: none;
  border-radius: 0 !important;
  padding: 3px 0 12px 0;
}

.customnavbar-line {
  width: 58px;
  height: 2px;
  margin-bottom: -6px;
  margin-top: -3px;
}

.customnavbar:hover {
  background-color: transparent !important;
}

.customnavbar:active {
  background-color: transparent !important;
}
