.modal-shape3 {
  padding-left: 60px;
  padding-right: 60px;
  min-width: 429px;
  min-height: 380.74px;
  max-width: 60%;
  max-height: 90%;
  width: fit-content;
  border-radius: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background: white;
  overflow-y: scroll;
}

.fs-21 {
  font-size: 21px;
}

.modal-shape-noscroll {
  overflow-y: hidden !important;
}
