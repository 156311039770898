.insight-impact-container {
  border-radius: 8px;
  background: #2a00ff1a;
  border: 0.61px solid #e1d6da;
  width: 700px;
}

.insight-impact-persona-container {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #7c4dff29;
}

.insight-impact-persona-card {
  width: 33.33%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  border-bottom: 1px solid #7c4dff29;
}

.insight-impact-persona-card:not(:nth-child(3n)) {
  border-right: 1px solid #7c4dff29;
}

.insight-impact-persona-card:nth-last-child(-n + 3):nth-child(3n + 1) {
  border-bottom: none;
}

.insight-impact-replay-btn,
.insight-impact-heatmap-btn {
  position: absolute;
}

.insight-impact-replay-btn {
  bottom: 54px;
}

.insight-impact-heatmap-btn {
  bottom: 0;
}

.insight-impact-btn {
  height: 44px !important;
  font-size: 14px !important;
  white-space: nowrap;
  width: 193px !important;
  margin-left: 3px;
}
