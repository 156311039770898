.dashboard-link-see-all {
  color: var(--main-blue) !important;
  font-weight: 900 !important;
  flex: none;
  margin-left: auto;
}

.dashboard-card-new {
  margin-bottom: 10px;
  min-width: 295px;
  max-width: 295px;
  min-height: 188.3px;
  border-radius: 6px;
  border: 2px solid var(--main-blue);
  color: var(--main-blue);
  background-color: white !important;
}

.dashboard-metrics-holder {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 17px 20px;
}

.dashboard-card-stats {
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
}

.dashboard-card-stats.positive {
  background-color: var(--dashboard-trend-positive) !important;
}

.dashboard-card-stats.negative {
  background-color: var(--dashboard-trend-negative);
}

.dashboard-card-stats.neutral {
  background-color: var(--dashboard-trend-neutral);
}

.dashboard-card-stats .decimals {
  font-size: 16px !important;
}
.dashboard-card-stats .decimals2 {
  font-size: 10px !important;
}
.dashboard-card-stats .decimals3 {
  font-size: 10px !important;
}

.dashboard-card-substats {
  border-radius: 5px;
  font-weight: 500;
}

.dashboard-card-substats.positive {
  color: var(--dashboard-trend-positive) !important;
  border-color: var(--dashboard-trend-positive) !important;
  border: 1px solid;
}

.dashboard-card-substats.negative {
  color: var(--dashboard-trend-negative) !important;
  border: 1px solid var(--dashboard-trend-negative) !important;
}

.dashboard-card-substats.neutral {
  color: var(--dashboard-trend-neutral) !important;
  border: 1px solid var(--dashboard-trend-neutral) !important;
}

.dashboard-card-substats.decimals {
  font-size: 16px;
}
.dashboard-card-substats.decimals2 {
  font-size: 10px !important;
}
.dashboard-card-substats.decimals3 {
  font-size: 10px !important;
}

.dashboard-pages-card {
  min-width: 295px;
  margin-right: 20px;
}

.last-pages-card {
  min-width: 160px;
  margin-right: 0;
}

.dashboard-pages-card,
.last-pages-card {
  padding: 20px !important;
  max-width: 295px;
  height: 188.3px;
  border-radius: 10px;
  background:
    linear-gradient(
      115.71deg,
      var(--main-blue) -4.42%,
      var(--medium-blue) 94.48%
    ),
    linear-gradient(0deg, #eeeeee, #eeeeee);
  margin-bottom: 10px;
  color: white;
}

.dashboard-pages-card .title,
.last-pages-card .title {
  align-self: flex-start;
  font-weight: 700;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
}

.dashboard-pages-card .body,
.last-pages-card .body {
  font-size: 20px;
  margin-bottom: 0px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Metrics */
.dashboard-metrics-card {
  flex-shrink: 0;
  width: 190px;
  height: 112px;
  font-weight: 700;
  font-size: 16px;
  padding: 16px 12px;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.dashboard-metrics-card .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 115px;
}

.dashboard-metrics-text {
  text-align: center;
  max-width: 142px;
  margin: 8px auto auto auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.dashboard-metrics-card .subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 5px;
  color: #707070;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dashboard-metrics-card .subpercent {
  font-size: 14px !important;
}

.dashboard-metrics-card .percent {
  margin-top: 4px;
  margin-right: 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}

.dashboard-metrics-card .custom-progress {
  margin-top: 4px;
  border-radius: 5px;
  padding: 4px 6px;
  flex-shrink: 0;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
}

.dashboard-metrics-card-new {
  width: 190px;
  height: 112px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px;
}

.dashboard-metrics-card-new button {
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  margin-top: 0 !important;
}
.icon-button {
  border: none;
  background: none;
  padding: 0;
  margin: 0 0 0 12px;
  flex-shrink: 0;
  display: flex;
}

.carousel-indicators {
  margin-bottom: 0.79rem;
}
.carousel-indicators button {
  border-radius: 50% !important;
  border: 1px solid #ffffff !important;
  outline: none !important;
  height: 12px !important;
  width: 12px !important;
  transition: background-color 0.6s ease !important;
  background-color: transparent !important;
  opacity: 1 !important;
  background-clip: border-box !important;
}

.carousel-indicators .active {
  background-color: #fff !important;
}

.carousel-control-next,
.carousel-control-prev {
  align-items: flex-start;
  opacity: 1;
  width: 24px;
  padding-top: 1rem;
  margin-top: 15px;
}

.carousel-control-prev {
  margin-left: 25px !important;
}

.carousel-control-next {
  margin-right: 25px !important;
}

.carousel-control-next:hover,
.carousel-control-next:focus,
.carousel-control-prev:hover,
.carousel-control-prev:focus {
  opacity: 1;
}
.modal-shape {
  max-width: 713px;
  min-width: 550px;
  width: 90%;
  height: 478px;
  border-radius: 20px;
  padding: 24px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.dropdown-section .option h5 {
  font-weight: 500;
  margin-left: 11px;
  font-size: 14px !important;
  margin-bottom: 0;
}

.option:hover {
  background: var(--blue-opacity10);
}

.reports-insights-cards-bigger {
  width: 505px !important;
  height: 282.13px !important;
  min-width: 505px !important;
  flex-shrink: 0;
}

/* Reports */
.dashboard-reports-card {
  color: white;
  border-radius: 10px;
  border: 0.5px solid #eee;
  background: #091a36;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  width: 400px;
  height: 208px;
}

.dashboard-reports-card .text {
  padding: 27.13px 0px 27.13px 25.25px !important;
}

.dashboard-reports-card .text-smaller {
  padding: 20px 0px 20px 20px !important;
}

.dashboard-reports-card-insight-title {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24.5px !important;
  letter-spacing: 0.08em !important;
}

.dashboard-reports-card-insight-body {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 28.8px !important;
  padding-bottom: 45px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-reports-card .image {
  width: 50%;
  object-fit: cover;
}

.dashboard-reports-card .date {
  font-size: 16px;
  font-weight: 900;
  line-height: 15px;
  width: 150%;
}

.dashboard-reports-card .body {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.chat-btn {
  border-radius: 100px !important;
  background: var(--medium-blue);
  color: white;
}

.vertical-line {
  width: 1px;
  height: 37px;
  background-color: #c2c2c2;
  margin: 0 15px;
}

.mobile-background {
  background: linear-gradient(116deg, #2a00ff -4.42%, #0062fe 94.48%);
  width: 100vw;
  height: 89vh;
}

.mobile-metrics-cards-holder {
  display: flex;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  gap: 0px 20px;
}

.dashboard-card-mobile-margin:first-child {
  margin-left: 20px;
}

.dashboard-card-mobile-margin:last-child {
  margin-right: 20px;
}

.fml-24:first-child {
  margin-left: 24px;
}

.example::-webkit-scrollbar {
  display: none;
}

.mobile-circle-button {
  padding: 0;
  margin-left: 25px;
  margin-right: 25px;
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
}

.mobile-circle-button:hover,
.mobile-circle-button:focus-visible,
.mobile-circle-button:active {
  outline: none;
  background: transparent !important;
}

.dashboard-card-mobile {
  margin-top: 24px;
  margin-bottom: 0;
  max-width: none;
  min-height: 0;
  width: 326px;
  height: 157px;
  flex-shrink: 0;
}

.metrics-button {
  margin-right: 24px;
  width: 81px;
  height: 48px;
}

button.unstyled-button {
  margin: 0;
  padding: 0;
  border: none;

  background-color: transparent;
  color: inherit;

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;

  text-decoration: none;
  width: 100%;
  height: 100%;
}

.fw-700 {
  font-weight: 700;
}

.book-analytics-expert-cta-button {
  border-radius: 50px !important;
  border: none;
  padding: 10px 16px;
  font-size: 16px;
  margin-bottom: 50px;
  background-color: #2a00ff;
}

.book-analytics-expert-cta-card {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: #f7f7f7;
  height: auto;
  font-size: 20px;
  line-height: 30px;
  margin-top: 31px;
}

.book-analytics-expert-cta-card .title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 48px;
  margin-bottom: 0;
}

.book-analytics-expert-cta-card .subtitle {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 32px;
  color: #707070;
}

.temporary {
  cursor: pointer;
  font-size: 10px;
  opacity: 0;
  transition: 300ms;
}

.temporary:hover {
  opacity: 1;
}
.pages-grid-header,
.pages-grid-header-vertical {
  margin-top: 6px;
}

.pages-grid-header {
  margin-bottom: 0px;
}

.metrics-grid-header,
.metrics-grid-header-vertical {
  margin-top: 10px;
}

.pages-grid,
.pages-grid-header,
.metrics-grid,
.metrics-grid-header {
  display: grid;
  gap: 20px;
}

.pages-grid,
.pages-grid-header {
  grid-template-columns: repeat(auto-fit, minmax(180px, 295px));
}

.metrics-grid,
.metrics-grid-header {
  grid-template-columns: repeat(auto-fit, minmax(190px, 190px));
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-has-no-submitted-reports-cards {
  max-width: 550px !important;
  min-width: 300px !important;
  width: 100% !important;
  height: 236px !important;
  padding-top: 45px !important;
  padding-left: 42px !important;
  margin-right: 5px !important;
}

.dashboard-has-no-submitted-reports-cards:last-child {
  margin-right: 0 !important;
}

.fs-20 {
  font-size: 20px !important;
}

.lh-27 {
  line-height: 27px !important;
}

.flower1-larger {
  width: 86.11px;
  height: 149.21px;
  position: absolute;
  margin-bottom: 0px;
  bottom: 0 !important;
  right: 85px !important;
  z-index: 1;
}

.flower2-larger {
  width: 129.95px;
  height: 225.17px;
  position: absolute;
  bottom: 0 !important;
  right: -45px !important;
  z-index: 1;
}

.flower1-smaller {
  width: 68.21px;
  height: 110px;
  position: absolute;
  bottom: 0 !important;
  right: 70px !important;
  z-index: 1;
}

.flower2-smaller {
  width: 102.93px;
  height: 166px;
  position: absolute;
  bottom: 0 !important;
  right: -35px !important;
  z-index: 1;
}

.create-project-success-modal-text p:not(:last-child) {
  margin-bottom: 4px;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.payment-modal-shape {
  width: 1332px !important;
  max-height: 1014.72px;
  border-radius: 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background: white;
  overflow-y: auto;
}

.payment-container {
  margin-left: 84px;
}

.payment-col1,
.payment-col2,
.payment-col3 {
  margin-right: 12px;
  /* Explicit min and max declarations due to row css issue*/
  max-width: 387px;
  min-width: 387px;
  max-height: 654px;
  min-height: 654px;
  margin-top: 140px;
}

.payment-content {
  padding-left: 12px;
  padding-top: 24px;
}

.payment-content-offset {
  margin-top: -44px !important;
}

.payment-col1 {
  margin-left: 10px;
  border: 1px solid #d7d7d7;
}

.payment-col2 {
  border: 2px solid var(--main-blue);
}

.payment-col3 {
  border: 2px solid #eb0d35;
}

.payment-col3 .plan-tab {
  background: #eb0d35;
}

.payment-col2 .plan-tab {
  background: var(--main-blue);
}

.plan-tab {
  width: 200px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  transform: translate(-12px, -46px);
}

.fs-32 {
  font-size: 32px;
}

.lh-192 {
  line-height: 19.2px !important;
}

.lh-216 {
  line-height: 21.6px !important;
}

.payment-ul-spacing li {
  margin-bottom: 12px;
}

.payment-select-blue,
.payment-select-red {
  flex-shrink: 0;
  flex-grow: 0;
  width: 339px !important;
  height: 44px;
  border-radius: 4px;

  font-size: 20px;
  font-weight: 500;
  line-height: 0px;
  letter-spacing: 0em;
  text-align: center;
  background-color: white;
}

.payment-select-blue {
  border: 2px solid #2a00ff;
  color: #2a00ff;
}

.payment-select-red {
  border: 2px solid #eb0d35;
  color: #eb0d35;
}

.blue-discount,
.red-discount {
  width: 71px;
  height: 39px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  padding-top: 3px;
  margin-top: -42px;
  margin-right: 13px;
}

.blue-discount {
  border: solid #2a00ff;
  color: #2a00ff;
}

.red-discount {
  border: solid #eb0d35;
  color: #eb0d35;
}

.styled-link:hover {
  cursor: pointer;
}

.fs-28 {
  font-size: 28px;
}

.chart-card-toggle-btn-active,
.chart-card-toggle-btn-inactive {
  width: 49px;
  height: 49px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  margin-right: 8px;
  cursor: pointer;
}

.chart-card-toggle-btn-active {
  background-color: var(--main-blue);
  color: white;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.chart-card-toggle-btn-inactive {
  background-color: white;
  color: "#0F0C22";
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.chart-card-toggle-row {
  position: absolute;
  right: 85px;
}

.lh-336 {
  line-height: 33.6px !important;
}

.dashboard-metrics-card .delete-metric-btn,
.dashboard-pages-card .delete-metric-btn {
  width: 23px;
  height: 23px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.dashboard-metrics-card:hover .delete-metric-btn,
.dashboard-pages-card:hover .delete-metric-btn {
  opacity: 1;
}

.dashboard-analysis-modal {
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
  background: #e3dff8;
  overflow: auto;
}

.analysis-progress .progress {
  height: 10px !important;
  background-color: #2a00ff1a !important;
  width: 70vw !important;
  margin-bottom: 0px !important;
  border-radius: 89px !important;
}

.analysis-progress .progress-bar {
  background-color: #2a00ff !important;
  border-radius: 89px !important;
}

.dashboard-card-common {
  padding: 24px;
  border-radius: 16px;
  height: 247px;
}
