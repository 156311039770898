.dropdown-section {
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 50px;
}

.dropdown-section .dropdown-control,
.dropdown-control {
  margin-bottom: 10px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: var(--off-white-two);
  color: #707070;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
}

.dropdown-section .dropdown-control-has-value,
.dropdown-control-has-value {
  padding: 22px 12px 2px 12px;
  color: black;
}

.dropdown-control-has-value-chip {
  padding: 12px 12px 2px 12px;
  color: black !important;
}

.dropdown-control:hover,
.dropdown-control-has-value:hover,
.dropdown-control-has-value-chip,
.dropdown-control-has-value-chip:hover {
  cursor: pointer;
}

.floating-label {
  color: #707070;
  font-size: 12px;
  position: absolute;
  top: 0;
  margin-top: 6px;
}

.floating-label-summary {
  color: #707070;
  font-size: 12px;
  position: absolute;
  top: 0;
  margin-top: 6px;
}

.floating-label-below {
  color: #707070;
  font-size: 16px;
  position: absolute;
  top: 50px;
}

.merged-menu-has-value,
.merged-menu {
  border-radius: 8px !important;
  padding: 5px !important;
  border: 1px solid #d7d7d7 !important;
  background: white !important;
  margin-bottom: 10px !important;
  height: fit-content !important;
}

.merged-menu {
  margin-top: -48px !important;
}

.merged-menu-has-value {
  margin-top: -85px !important;
}

.merged-menu-has-value > div,
.merged-menu > div {
  height: fit-content !important;
  max-height: fit-content !important;
}

.merged-menu-label {
  color: var(--main-blue);
  font-weight: 400 !important;
  margin-top: -13px;
  font-size: 14px;
  margin-bottom: 1px;
  margin-left: -1px;
}

.merged-menu-chevron {
  position: absolute;
  right: 7px;
}

.dropdown-section .chevron-value-offset,
.chevron-value-offset {
  transform: translateY(-10px);
}

.dropdown-section .menu,
.menu {
  margin-top: 10px;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #eee;
  background: white;
}

.dropdown-section .option,
.option {
  font-size: 14px;
  font-weight: 500;
  border: 5px;
  border-radius: 10px;
  padding: 12px 8px;
  align-items: center;
  vertical-align: middle;
  overflow-wrap: break-word;
}
.option-wrapper {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.option-icon {
  min-width: 20px;
  min-height: 20px;
  margin-right: 16px;
  font-size: 20px;
  color: #000;
}
.option-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: inherit;
  font-weight: inherit;
}
.option-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #707070;
  margin-top: 5px;
}
.option-separator {
  border-top: solid 1px #d7d7d7;
  border-radius: 0;
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.option:hover {
  background: var(--blue-opacity10);
  cursor: pointer;
}

.option.no-highlight:hover {
  background: inherit !important;
  cursor: pointer;
}

/* Mobile dropdown CSS */
.dropdown-section-2 .menu {
  width: calc(100% - 50px);
}
.dropdown-section-2 .menu,
.dropdown-section-2 .dropdown-control {
  margin-left: 25px;
  margin-right: 25px;
}

.dropdown-section-2 .dropdown-control {
  height: 49px;
  border: 1px solid #d7d7d7;
}

.dropdown-control-has-summary {
  height: 85px;
}

.dropdown-control-has-summary .select-selected-option {
  position: absolute;
  top: -13px;
  overflow: visible !important;
  font-size: 16px;
}

.dropdown-control-has-summary div {
  overflow: visible !important;
}

.dropdown-section-2 .option,
.dropdown-section .option {
  display: flex;
}

.dropdown-filter {
  width: 300px;
}

.dropdown-filter-width-127 {
  width: 127px;
  margin-right: 12px;
}

.dropdown-filter-width-160 {
  width: 160px;
  margin-right: 12px;
}

.dropdown-filter-width-200 {
  width: 200px;
  margin-right: 12px;
}
