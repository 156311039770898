.report-card {
  background-color: var(--projects-tab-color-active);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 245px;
  height: 170px;
  margin-right: 15px;
}

.report-card-text {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}

.report-card-subtext {
  font-weight: 700;
}

.reports-title {
  color: var(--standard-font-color);
  margin-top: 14px;
  font-weight: 600;
  font-size: 20px;
}

.reports-header {
  height: 66px;
  background-color: var(--off-white-two);
  border-radius: 6px 6px 0 0;
  border-width: 2px;
  border-color: var(--off-white-three);
}

.reports-header-faded {
  width: 100%;
  height: 100%;
  min-height: 66px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.reports-table-image {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

tbody tr:not(:last-child) {
  border-bottom: 1px solid #d7d7d7;
}

.ml-10 {
  margin-left: 10px;
}
