.contract-container {
  background-color: #f7f7f7;
  max-width: 819.56px;
  min-height: 500.2px;
  border-radius: 16px;
  padding: 33px;
}

.contact-spacer {
  margin-bottom: 32.5px;
}
