/* CustomSidebar migration styles ========================================= */

.expanded-menu-subtitle {
  margin-left: 15px;
}

.arrow-button {
  width: 38.86px;
  height: 54px;
  z-index: 11;
  transition: left 0.25s;
  border-radius: 0 9px 9px 0 !important;
  top: 102px;
  background-color: #f9f9f9;
  border: 0;
}

.arrow-button:hover,
.arrow-button:active,
.arrow-button:focus {
  background-color: #f9f9f9 !important;
}
.sidebar-message-button {
  width: 100%;
  padding: 0;
  background-color: transparent;
}

.sidebar-message-button:hover {
  background-color: #f3f3f3;
}

.expanded-menu-label,
.expanded-menu-label-active {
  font-size: 18px;
}

.expanded-menu-label-active {
  color: var(--main-blue);
  font-weight: 700;
}

.expanded-menu-label {
  color: var(--standard-font-color);
  font-weight: 500;
}

.dropdown {
  height: 62.52px;
  margin-top: 5px;
}

.projects-control {
  margin: 0 10px;
  width: 190px;
}

.expanded-projects-control {
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.expanded-projects-control > div:first-child {
  display: flex;
  align-items: end;
  cursor: pointer;
}

.expanded-projects-control > div > div:first-child {
  width: 302px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 20px;
  border-bottom: 1px solid #d7d7d7;
}

.expanded-projects-control > div > div:nth-child(2) {
  z-index: 99;
  cursor: pointer;
}

.projects-placeholder {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 110px;
}

.projects-menu,
.expanded-projects-control > div > div:first-child {
  font-size: 18px;
  text-align: left;
}

.projects-menu {
  padding-top: 5px;
  background: white;
  margin-top: 50px;
  min-width: 210px;
  cursor: pointer;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.projects-menu::before {
  content: "";
  position: absolute;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  top: 0px;
  right: 0;
  bottom: 0px;
  left: 0;
  box-shadow: 7px 0px 0px 0px #2a00ff;
  z-index: -100;
}

.projects-menu > div > div:nth-child(n + 1):hover {
  background: var(--off-white-two);
}

.projects-menu div div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-dropdown-image,
.create-project-image {
  float: left;
  border-radius: 5px;
}

.project-dropdown-image {
  height: 32px;
  width: 32px;
  margin: 10px 12px 7px 12px;
}

.create-project-image {
  height: 32px;
  width: 28px;
  margin: 13px 25px 7px 16px;
}

.selected-tab {
  width: 4px;
  height: 50px;
  margin-bottom: 7px;
}

.selected-channel {
  position: relative;
  width: 4px;
  height: 100px;
  z-index: 100;
}

.mobile-sidebar {
  bottom: 0;
  height: 11vh;
  width: 100%;
  position: fixed;
  background-color: var(--off-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-sidebar-button {
  margin-bottom: calc(10vh - 72px);
  padding: 3.5% 0 6% 0;
  border: none;
  background-color: transparent;
  flex: 1;
  text-align: center;
}

.mobile-sidebar-button:hover {
  border: none;
  background-color: transparent;
}

.mobile-sidebar-button:active {
  border: none;
  background-color: transparent !important;
}

.number-circle {
  width: 24px;
  height: 24px;
  background: #0f0c22;
  color: white;
  border-radius: 50%;
  z-index: 100;
  margin-right: 14px;
}

.sidebar-menu-item:not(:last-child) {
  margin-bottom: 7px !important;
}

.chat-channel-link {
  cursor: pointer;
}

.chat-channel-link img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.hidden-scrollbar div::-webkit-scrollbar {
  display: none;
  -webkit-overflow-scrolling: touch;
}

.hidden-scrollbar div::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
  -webkit-overflow-scrolling: touch;
}

.hidden-scrollbar div {
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch;
}

.logout-btn {
  width: 100%;
  background: var(--off-white-two);
  margin-bottom: 170px;
  border-radius: 0px !important;
}

.logout-btn:hover {
  background-color: var(--off-white);
}

.sidebar-spinner-parent {
  margin-left: 25px;
  margin-top: 19px;
  margin-bottom: 18px;
}

.logout-btn-light {
  width: 100%;
  background: black;
  position: absolute;
  bottom: 0;
}

@media (max-width: 576px) {
  .arrow-button {
    display: none !important;
  }
}

.v4-tooltip > div.tooltip-inner {
  max-height: 50px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px !important;
}

.v4-tooltip-tall > div.tooltip-inner {
  max-width: 170px !important;
}

.v4-tooltip > div.tooltip-inner,
.v4-tooltip-tall > div.tooltip-inner {
  background-color: #0f0c22 !important;
  color: white !important;
  box-shadow: 4px -3px 0px 0px #2a00ff;
  font-size: 16px;
  padding: 12px;
  font-weight: 700;
  font-family: "satoshi" !important;
  border-radius: 8px !important;
  margin-left: 37px;
}

.v4-tooltip.show,
.v4-tooltip-tall.show {
  opacity: 1 !important;
}
.v4-tooltip > div.tooltip-arrow::before,
.v4-tooltip-tall > div.tooltip-arrow::before {
  display: none !important;
}

.topbar-tooltip > div.tooltip-inner {
  font-size: 16px;
  max-height: unset !important;
  text-align: left;
  background-color: var(--off-white-two) !important;
  color: var(--black-200) !important;
  box-shadow:
    4px -3px #2a00ff,
    0px 0px 2px rgba(0, 0, 0, 0.25) !important;
  max-width: 305px !important;
}

.v4-tooltip-follow-blue {
  background-color: #2a00ff !important;
  color: white;
  font-size: 16px;
  padding: 12px;
  font-weight: 700;
  font-family: "satoshi" !important;
  border-radius: 8px !important;
  margin-left: 37px;
  margin-top: -35px;
  max-width: 175px;
}

.v4-tooltip-follow-dark {
  background-color: #0f0c22 !important;
  box-shadow: 4px -3px 0px 0px #2a00ff;
  color: white;
  font-size: 16px;
  padding: 12px;
  font-weight: 700;
  font-family: "satoshi" !important;
  border-radius: 8px !important;
  margin-left: 37px;
  margin-top: -35px;
  max-width: 300px;
  min-width: fit-content;
  overflow: hidden;
}

.v4-project-list {
  box-shadow: 7px -7px 0px 0px #2a00ff;
  width: 240.02px;
  border-radius: 8px;
  z-index: 500;
  left: 150px;
  top: 250px;
  padding-top: 27px;
  padding-bottom: 23px;
  position: fixed;
  background-color: white;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  box-sizing: border-box;
}

.v4-project-list::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.navigate-to-manage {
  z-index: 999;
  position: absolute;
  top: 59px;
  left: 59px;
  font-size: 16px;
  padding: 5px;
  font-weight: 500;
}

.settings-chip {
  position: absolute;
  background: #0f0c22;
  color: white;
  font-size: 14px;
  font-weight: 700;
  left: 13px;
  top: 145px;
  border-radius: 24px;
  padding: 2px 9px;
}

/* END CustomSidebar migration styles ========================================= */

.navbar {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 70px;
}

.navbar:active {
  background-color: white;
}

.nav-link-custom {
  position: relative;
  padding: 0px 12px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.nav-link-custom.active-tab::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -22px;
  height: 3px;
  width: 32px;
  background-color: var(--main-blue);
}

.nav-link-custom-chat.active-tab::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-59%);
  bottom: -9px;
  height: 3px;
  width: 48px;
  background-color: var(--main-blue);
}

.navbar-nav {
  align-items: center;
}

.nav-link {
  padding: 0px 12px !important;
  display: flex;
  align-items: center;
}

.message-icon {
  padding: 0px !important;
}

.dropdown {
  margin-top: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-self: center;
}

.dropdown-toggle {
  padding: 0 !important;
  margin-right: 78px;
}

.v4-project-list {
  width: 303.02px;
  left: 200px;
  top: 95px;
}

.v4-profile-list {
  position: absolute;
  top: 150%;
  left: -125px;
  width: 160px;
  background: #2a0ff2;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 0.5px solid #ccc;
}

.profile-container {
  position: relative;
  margin-right: 130px;
}

.dropdown {
  height: 60px;
}

.profile-placeholder {
  display: none;
}

.profile-menu > div > div:first-child {
  font-size: 18px;
  text-align: left;
}

.profile-menu {
  padding-top: 5px;
  background: white;
  margin-top: -80px;
  min-width: 160px;
  cursor: pointer;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  left: -85px;
  border: 0.5px solid #ccc;
}

.profile-menu::before {
  content: "";
  position: absolute;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  top: 0px;
  right: 0;
  bottom: 0px;
  left: 0;
  z-index: -100;
}

.profile-menu > div > div:nth-child(n + 1):hover {
  background: var(--off-white-two);
}

.profile-menu div div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vertical-line {
  border-left: 1px solid lightgrey;
  height: 40px;
  margin: 0px 32.3px;
}

.profile-option-icon {
  color: black;
  font-size: 25px;
}

.navbar-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 900;
  min-width: 1100px;
}

.navbar-absolute {
  position: absolute;
  top: 0;
  width: 100vw;
  min-width: 1100px;
}
