.appa-btn {
  width: 106px !important;
}

.admin-emails > * + * {
  margin-bottom: 20px;
}

.admin-expand-chevron {
  padding-left: 32px;
  background: none;
}

.admin-expand-tbody-border-bottom:not(:last-child) {
  border-bottom: 1px solid #d7d7d7;
}
