.legal-sidebar {
  background-color: #f7f7f7;
  float: left;
  height: 100%;
  overflow-y: auto;
}

.legal-main-content {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.legal-list {
  margin-top: 40px;
  list-style-type: disc;
}

.legal-list-item {
  margin-bottom: 15px;
  color: var(--main-blue);
}

.legal-link {
  font-weight: bold !important;
  text-decoration: underline !important;
  text-underline-offset: 4px;
  font-size: 16px;
}

.legal-link i {
  margin-left: 8px;
}
