.recording-modal {
  position: fixed;
  top: 120px;
  left: 100px;
  right: 100px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 0px;
  z-index: 1001;
  min-height: 700px;
  height: fit-content;
  border-radius: 10px;
  min-width: 900px;
}

.recording-frame-container {
  position: relative;
  width: 100%;
  height: fit-content;
}

.recording-modal-content {
  margin-left: 100px;
  margin-right: 100px;
  min-width: 750px;
}

.close-icon-32 {
  position: absolute;
  top: 32px;
  right: 32px;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}
