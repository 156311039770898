.nav-section a {
  color: #0f0c22 !important;
  font-size: 16px;
  line-height: 20px;
  margin-right: 24px;
  font-weight: 500;
}

.nav-section a:hover {
  color: var(--main-blue) !important;
  font-weight: 700;
}

.nav-line {
  width: 1154.449px;
  height: 1px;
  background: rgba(42, 0, 255, 0.1);
  margin-top: 12px;
  margin-bottom: 69px;
}

.todo-tab-item {
  background-color: transparent;
  border: none;
  padding: 3px 10px 5px 0;
  margin-right: 22px;
}

.todo-tab-item:active,
.todo-tab-item:hover {
  background-color: transparent !important;
}

.todo-tab-bar {
  height: 2px;
  margin-bottom: -13px;
}

.todo-tab-item .active-tab {
  color: var(--main-blue) !important;
  font-weight: 700;
}

.user {
  color: var(--black-100);
  font-feature-settings:
    "cv11" on,
    "cv01" on,
    "ss01" on;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 50.67px;
}

.todo-card {
  border-radius: 10px;
  background: rgba(42, 0, 255, 0.1);
  width: 301px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 22px 22px 10px 22px;
  height: 289px;
}

.todo-card-first {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}

.todo-card-title-holder {
  height: 83.25px;
}

.todo-card-title {
  width: 280px;
  margin-top: 32px;
  padding-bottom: 2px;
  color: #2a00ff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.todo-card-second {
  display: flex;
  padding: 31.75px 32px 36px 20px;
}

.due-date {
  margin-right: 27px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #0f0c22;
}

.due-date-details {
  margin-top: 4px;
  font-weight: 700;
  line-height: 15px;
  color: #2a00ff;
  text-align: left;
  width: 160px;
}

.todo-card button {
  background-color: #2a00ff;
  width: 38px;
  height: 38px;
  display: flex;
  padding: 10px 10.219px 10.219px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
}

.todo-card-line {
  width: 300px;
  border: 1px solid var(--main-blue);
  margin: 0 0 0 -22px;
}

.todo-card-img {
  height: 50px;
  width: 50px;
}

.custom-column {
  flex: 0 0 auto;
  width: 301px;
  margin-right: 20px;
}

.lh-30 {
  line-height: 30px;
}

.p-p0 {
  padding: 0 !important;
}

.disabled-contract {
  background: #2a00ff26 !important;
}
